import sliceCreator from '../index.js';
import {EMPLOYEE_RATING} from "../../../constants/index.js"

const _employeeRatingAction = sliceCreator('EMPLOYEE_RATING', EMPLOYEE_RATING, 'post');

const { asyncAction: employeeRatingAction, reducer, clearData: clearemployeeRatingAction } = _employeeRatingAction;

export default reducer;

export {
    employeeRatingAction,
    clearemployeeRatingAction
}
