import * as Yup from 'yup';

export const VerifyExistSchema = Yup.object({
  startDate: Yup.string()
    .required('Start date is required'),
  
    endDate: Yup.date()
    .nullable()
    .when(['startDate', 'isCurrentExperience'], ([startDate, isCurrentExperience], schema) => {
      if (isCurrentExperience) {
        return schema.nullable()
      }
      return startDate ? schema.min(startDate, "End date must be after start date").required('End date is required') : schema
    }),
  
  designation: Yup.string()
    .required('Designation is required'),
  
  rating: Yup.number()
    .min(1, 'Rating is required')
    .max(5, 'Rating cannot exceed 5')
    .required('Rating is required'),
  
  remarks: Yup.string()
    .required('Remarks are required')
    .min(10, 'Remarks must be at least 10 characters'),
  
  isCurrentExperience: Yup.boolean(),

  existVerified: Yup.boolean()
    .required()
    .oneOf([true], 'You must verify exit'),
});
