import React, { useState, useEffect } from 'react';
import {Button,Card,CardContent,Grid,TextField,IconButton,Typography,} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import {clearSendOtpData,clearVerifyOtpData,sendOtpAction,verifyOtpAction,} from "../../../store/actions";
import { registrationAction } from '../../../store/actions';
import { useNavigate } from "react-router-dom";
import statesData from '../../../utils/statesdata';
import MenuItem from '@mui/material/MenuItem';

export default function EmployeeRegistration() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const [emailOtpSent, setEmailOtpSent] = useState(false);
    const [phoneOtpSent, setPhoneOtpSent] = useState(false);
    const [emailOtpTimer, setEmailOtpTimer] = useState(30);
    const [phoneOtpTimer, setPhoneOtpTimer] = useState(30);
    const [identifier, setIdentifier] = useState("");
    const [verifyIdentifier, setVerifyIdentifier] = useState("");
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [districts, setDistricts] = useState([]);


    const [formData, setFormData] = useState({
        fullName: "",
        fatherName: "",
        city: "",
        district: "",
        state: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        emailOtp: "",
        phoneOtp: "",
        panNumber: "",
        confirnPanNumber: "",
        dateOfBirth: "",
        termsAndConditions: false, 
    });

    const [errors, setErrors] = useState({});

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setConfirmPassword(!confirmPassword);
    };

    const handleChange = (event) => {
        const { name, value,checked,type } = event.target;

        // Validation logic
        const validateInput = (name, value) => {
            switch (name) {
                case 'phone':
                    return /^[6-9]\d{9}$/.test(value) ? '' : 'Phone number is not valid.';
                case 'email':
                    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Email is not valid.';
                case 'password':
                    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^\s]{8,15}$/.test(value) ?
                        '' :
                        'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 to 15 characters long.';
                case 'confirmPassword':
                    return value === formData.password ? '' : 'Passwords do not match.';
                    case 'panNumber':
            if (value.length === 10) {
                return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) 
                    ? '' 
                    : 'Invalid PAN number format';
            }
                    case 'confirmPanNumber':
                        return value === formData.panNumber 
                            ? '' 
                            : 'PAN numbers do not match';
                default:
                    return '';
            }
        };

        const updatedValue = name === 'panNumber' || name === 'confirmPanNumber' 
        ? value.toUpperCase()
        : value;

    setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
    }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: validateInput(name, value,updatedValue),
        }));

        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateInput = (name, value) => {
        switch (name) {
          case 'phone':
            return /^[6-9]\d{9}$/.test(value) ? '' : "Phone number is required.";
          case 'email':
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Email is required.';
          case 'password':
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^\s]{8,15}$/.test(value) ?
              '' :
              'Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be between 8 to 15 characters long.';
          case 'confirmPassword':
              return value === formData.password ? '' : 'Passwords do not match.';
          case 'panNumber':
              return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) ? '' : 'Invalid PAN number format';
          case 'confirmPanNumber':
              return value === formData.panNumber ? '' : 'PAN numbers do not match';
          case 'fullName':
              return /^[a-zA-Z\s]+$/.test(value) ? '' : 'Full name is required.';
          case 'fatherName':
              return /^[a-zA-Z\s]+$/.test(value) ? '' : 'Father name is required.';
          case 'city':
              return /^[a-zA-Z\s]+$/.test(value) ? '' : 'City is required.';
          case 'district':
              return /^[a-zA-Z\s]+$/.test(value) ? '' : 'District is required.';
          case  'state':
              return /^[a-zA-Z\s]+$/.test(value) ? '' : 'State is required.';
          case 'dateOfBirth':
              return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value) ? '' : 'Invalid date format. Use YYYY-MM-DD.';
          
          
          default:
            return '';
        }
      };
  
    const handleBlur = (event) => {
        const { name, value } = event.target;
        const error = validateInput(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
        
    
        // Validate confirmPassword onBlur
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: validateInput(name, value),
          confirmPassword: name === 'confirmPassword' && formData.password !== value ? 'Passwords do not match.' : prevErrors.confirmPassword,
        }));
      };
    const handleSubmit = (event) => {
        event.preventDefault();

        if (validate()) {
            const formDataToSend = new FormData();
            const payload = {
                user: {
                    first_name: formData.fullName,
                    father_name: formData.fatherName,
                    date_of_birth: formData.dateOfBirth,
                    pan_number: formData.panNumber,
                    email: formData.email,
                    phone_number: formData.phone,
                    is_employee: true,
                    role: "employee",
                    user_type: 13,
                    state: formData.state,
                    district: formData.district,
                    city: formData.city,
                    image: formData.profileImage,
                    password1: formData.password,
                    password2: formData.confirmPassword,
                }
            };
            
            Object.keys(formData).forEach(key => {
                if (key === 'profileImage') {
                    formDataToSend.append('profile_image', formData.profileImage);
                } else if (key !== 'profileImagePreview') {
                    formDataToSend.append(key, formData[key]);
                }
            });
            dispatch(registrationAction(payload)) 
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file type
            if (!file.type.startsWith('image/')) {
                toast.error('Please upload an image file');
                return;
            }
            // Validate file size (2MB limit)
            if (file.size > 2 * 1024 * 1024) {
                toast.error('Image size should be less than 2MB');
                return;
            }

            setFormData(prev => ({
                ...prev,
                profileImage: file,
                profileImagePreview: URL.createObjectURL(file)
            }));
        }
    };

    // Cleanup function for image preview URL
    useEffect(() => {
        return () => {
            if (formData.profileImagePreview) {
                URL.revokeObjectURL(formData.profileImagePreview);
            }
        };
    }, [formData.profileImagePreview]);

    const validate = () => {
        const tempErrors = {};
        if (!formData.panNumber) {
            tempErrors.panNumber = "PAN number is required";
        } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panNumber)) {
            tempErrors.panNumber = "Invalid PAN number format";
        }
        if (formData.panNumber) {
            if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panNumber)) {
              tempErrors.panNumber = "Invalid PAN number format";
            }
          }
        if (!formData.fullName) tempErrors.fullName = "Full name is required";
        if (!formData.fatherName) tempErrors.fatherName = "Father's name is required";
        if (!formData.city) tempErrors.city = "City is required";
        if (!formData.district) tempErrors.district = "District is required";
        if (!formData.state) tempErrors.state = "State is required";
        if (!formData.email) tempErrors.email = "Email is required";
        if (!formData.phone) tempErrors.phone = "Phone number is required";
        if (!formData.password) tempErrors.password = "Password is required";
        if (formData.password !== formData.confirmPassword) {
            tempErrors.confirmPassword = "Passwords do not match";
        }
        if (!formData.dateOfBirth) {
            tempErrors.dateOfBirth = "Date of Birth is required";
        }
        if (!formData.termsAndConditions) {
            tempErrors.termsAndConditions = "Please read & accept the terms and conditions.";
        }
    
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
    const {
        successData: sendOtpSuccessData,
        loading: sendOtpLoading,
        error: sendOtpError,
        errorInfo: sendOtpErrorInfo,
    } = useSelector((state) => state.sendOtpReducer) || {};

    const {
        successData: verifyOtpSuccessData,
        loading: verifyOtpLoading,
        error: verifyOtpError,
        errorInfo: verifyOtpErrorInfo,
    } = useSelector((state) => state.verifyOtpReducer) || {};

    const { successData:registrationSuccessData, error, errorInfo } =
    useSelector((state) => state.registrationData) || {};

    const handleSendOtp = (identifier) => {
    console.log('Sending OTP for:', identifier);
    setIdentifier(identifier);
    switch (identifier) {
        case "email":
            dispatch(sendOtpAction({ identifier: formData.email }));
            break;
        case "phone":
            dispatch(sendOtpAction({ identifier: formData.phone }));
            break;
        default:
            toast.error("Please try again");
    }
};

    const handleVerifyOtp = (identifier) => {
        setVerifyIdentifier(identifier);
        switch (identifier) {
            case "email":
                dispatch(
                    verifyOtpAction({
                        identifier: formData.email,
                        otp: formData.emailOtp,
                    })
                );
                break;
            case "phone":
                dispatch(
                    verifyOtpAction({
                        identifier: formData.phone,
                        otp: formData.phoneOtp,
                    })
                );
                break;
            default:
                toast.error("Please try again");
        }
    };
    useEffect(() => {
        if (sendOtpSuccessData && identifier) {
            if (identifier === "email") {
                toast.success("OTP sent to email");
                setEmailOtpSent(true);
                setEmailOtpTimer(300);
                setIdentifier("");
                dispatch(clearSendOtpData());
            } else if (identifier === "phone") {
                toast.success("OTP sent to phone number");
                setPhoneOtpSent(true);
                setPhoneOtpTimer(300);
                setIdentifier("");
                dispatch(clearSendOtpData());
            }
        } else if (sendOtpError) {
            toast.error(sendOtpErrorInfo || "Failed to send OTP");
            dispatch(clearSendOtpData());
        }
    }, [sendOtpSuccessData, sendOtpError, sendOtpErrorInfo, identifier,dispatch]);

    useEffect(() => {
        if (verifyOtpSuccessData && verifyIdentifier) {
            if (verifyIdentifier === "email") {
                toast.success("Email verified successfully");
                setEmailOtpSent(false);
                setIsEmailVerified(true);
                setVerifyIdentifier("");
                dispatch(clearVerifyOtpData());
            } else if (verifyIdentifier === "phone") {
                toast.success("Phone number verified successfully");
                setIsPhoneVerified(true);
                setPhoneOtpSent(false);
                setVerifyIdentifier("");
                dispatch(clearVerifyOtpData());
            }
        } else if (verifyOtpError) {
            toast.error(verifyOtpErrorInfo || "Failed to verify OTP");
            dispatch(clearVerifyOtpData());
        }
    }, [verifyOtpSuccessData, verifyOtpError, verifyOtpErrorInfo, verifyIdentifier,dispatch]);

    useEffect(() => {
        if (registrationSuccessData) {
            toast.success("Registration successful");
            navigate('/employee')
            // dispatch(clearRegistrationData());
        } else if (error) {
            toast.error(errorInfo || "Failed to register");
            // dispatch(clearRegistrationData());
        }
    }, [registrationSuccessData, error, errorInfo, dispatch]);
    return (
        <Grid container justifyContent="center" >
            <Grid item xs={10} lg={8} mb={2}>
                <Card sx={{ width: "100%", padding: "15px" }} elevation={20}>
                    <CardContent>
                        <form onSubmit={handleSubmit} onChange={handleChange}>
                            <Grid container spacing={3}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Typography variant='h5' sx={{ fontWeight: 'bold', fontSize: '30px', color: '#65A3DD', background: 'linear-gradient(90deg, #553ebd, #7a538e)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>Sign up as Employee</Typography>
                                </Grid>
                                {/* Profile Image Upload */}
                                <Grid item xs={12} className="text-center">
                                    <div >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="profile-image-upload"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor="profile-image-upload">
                                            <div style={{
                                                width: "100px",
                                                height: "100px",
                                                position: "relative",
                                                cursor: "pointer",
                                                overflow: "hidden",
                                                borderRadius: "50%",
                                            }}>
                                                {formData.profileImagePreview ? (
                                                    <img
                                                        src={formData.profileImagePreview}
                                                        alt="Profile Preview"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "50%",
                                                            objectFit: "cover",
                                                            border: "2px solid #2b4257"
                                                        }}
                                                    />
                                                ) : (
                                                    <div style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                        backgroundColor: "#f5f5f5",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        border: "2px solid #2b4257"
                                                    }}>
                                                        <span>Upload Photo</span>
                                                    </div>
                                                )}
                                            </div>
                                        </label>
                                        {errors.profileImage && (
                                            <p style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '3px' }}>
                                                {errors.profileImage}
                                            </p>
                                        )}
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="PAN Number"
                                        name="panNumber"
                                        value={formData.panNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        inputProps={{ 
                                            style: { textTransform: 'uppercase' },
                                            maxLength: 10 
                                        }}
                                        InputProps={{
                                            onChange: (e) => {
                                                e.target.value = e.target.value.toUpperCase();
                                            }
                                        }}
                                        error={!!errors.panNumber}
                                        helperText={errors.panNumber}
                                    />
                                </Grid>

                                {/* Confirm PAN Number */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Confirm PAN Number"
                                        name="confirmPanNumber"
                                        value={formData.confirmPanNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        inputProps={{ 
                                            style: { textTransform: 'uppercase' },
                                            maxLength: 10 
                                        }}
                                        InputProps={{
                                            onChange: (e) => {
                                                e.target.value = e.target.value.toUpperCase();
                                            }
                                        }}                                        error={!!errors.confirmPanNumber}
                                        helperText={errors.confirmPanNumber}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Full Name"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        error={!!errors.fullName}
                                        helperText={errors.fullName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Father's Name"
                                        name="fatherName"
                                        value={formData.fatherName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        error={!!errors.fatherName}
                                        helperText={errors.fatherName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Date of Birth"
                                        name="dateOfBirth"
                                        type="date"
                                        value={formData.dateOfBirth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!errors.dateOfBirth}
                                        helperText={errors.dateOfBirth}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
    <TextField
        select
        label="State"
        name="state"
        value={formData.state}
        onChange={(e) => {
            handleChange(e);
            const selectedState = statesData.find(state => state.value === e.target.value);
            setDistricts(selectedState ? selectedState.districts : []);
        }}
        onBlur={handleBlur}
        fullWidth
        error={!!errors.state}
        helperText={errors.state}
    >
        {statesData.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </TextField>
</Grid>

<Grid item xs={12} md={6}>
    <TextField
        select
        label="District"
        name="district"
        value={formData.district}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        error={!!errors.district}
        helperText={errors.district}
        disabled={!formData.state}
    >
        {districts.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </TextField>
</Grid>

                                <Grid item xs={12} md={6}>
                                <TextField
                                        label="City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        error={!!errors.city}
                                        helperText={errors.city}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        autoComplete="new-password"
                                        disabled={emailOtpSent || isEmailVerified}
                                        variant="outlined"
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        sx={{ marginBottom: "6px" }}
                                    />
                                    {isEmailVerified && (
                                        <div style={{ display: "flex", alignItems: "center", color: "green" }}>
                                            <CheckCircleIcon />
                                            <span>Verified</span>
                                        </div>
                                    )}
                                    {!errors.email && formData.email && (
                                        <>
                                            {emailOtpSent && (
                                                <>
                                                    <span>OTP Expires in :</span>{" "}
                                                    <span className="text-danger">
                                                        {Math.floor(emailOtpTimer / 60)}:
                                                        {emailOtpTimer % 60 < 10 ? "0" : ""}
                                                        {emailOtpTimer % 60}
                                                    </span>
                                                    {"   "}
                                                </>
                                            )}
                                            <div className="signup-otp-container">
                                                {!emailOtpSent && !isEmailVerified && (
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        variant="contained"
                                                        className="signup-otp-button"
                                                        disabled={sendOtpLoading}
                                                        onClick={() => handleSendOtp("email")}
                                                    >
                                                        {sendOtpLoading ? (
                                                            <CircularProgress size={24} style={{ color: "#fff" }} />
                                                        ) : (
                                                            "Send OTP"
                                                        )}
                                                    </Button>
                                                )}
                                                {emailOtpSent && (
                                                    <>
                                                        <TextField
                                                            type="text"
                                                            name="emailOtp"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="signup-otp-input"
                                                            placeholder="OTP sent to email"
                                                        />
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            variant="contained"
                                                            className="signup-otp-button"
                                                            disabled={verifyOtpLoading || formData.emailOtp.length !== 6}
                                                            onClick={() => handleVerifyOtp("email")}
                                                        >
                                                            Submit OTP
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                            {emailOtpSent && (
                                                <span className="signup-otp-timer">
                                                    <button
                                                        className="signup-otp-action"
                                                        onClick={() => {
                                                            handleSendOtp("email");
                                                            setEmailOtpSent(false);
                                                        }}
                                                    >
                                                        Resend OTP
                                                    </button>{" "}
                                                    or
                                                    <button
                                                        className="signup-otp-action"
                                                        onClick={() => setEmailOtpSent(false)}
                                                    >
                                                        {" "}
                                                        Change email
                                                    </button>
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        autoComplete="off"
                                        type="tel"
                                        disabled={phoneOtpSent || isPhoneVerified}
                                        variant="outlined"
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        sx={{ marginBottom: "6px" }}
                                    />
                                    {isPhoneVerified && (
                                        <div style={{ display: "flex", alignItems: "center", color: "green" }}>
                                            <CheckCircleIcon />
                                            <span>Verified</span>
                                        </div>
                                    )}
                                    {!errors.phone && formData.phone && (
                                        <>
                                            {phoneOtpSent && (
                                                <>
                                                    <span>OTP Expires in :</span>{" "}
                                                    <span className="text-danger">
                                                        {Math.floor(phoneOtpTimer / 60)}:
                                                        {phoneOtpTimer % 60 < 10 ? "0" : ""}
                                                        {phoneOtpTimer % 60}
                                                    </span>
                                                    {"   "}
                                                </>
                                            )}
                                            <div className="signup-otp-container">
                                                {!phoneOtpSent && !isPhoneVerified && (
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        variant="contained"
                                                        className="signup-otp-button"
                                                        disabled={sendOtpLoading}
                                                        onClick={() => handleSendOtp("phone")}
                                                    >
                                                        {sendOtpLoading ? (
                                                            <CircularProgress size={24} style={{ color: "#fff" }} />
                                                        ) : (
                                                            "Send OTP"
                                                        )}
                                                    </Button>
                                                )}
                                                {phoneOtpSent && (
                                                    <>
                                                        <TextField
                                                            type="text"
                                                            name="phoneOtp"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="signup-otp-input"
                                                            placeholder="OTP sent to phone"
                                                        />
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            variant="contained"
                                                            className="signup-otp-button"
                                                            disabled={verifyOtpLoading || formData.phoneOtp.length !== 6}
                                                            onClick={() => handleVerifyOtp("phone")}
                                                        >
                                                            Submit OTP
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                            {phoneOtpSent && (
                                                <span className="signup-otp-timer">
                                                    <button
                                                        className="signup-otp-action"
                                                        onClick={() => {
                                                            handleSendOtp("phone");
                                                            setPhoneOtpSent(false);
                                                        }}
                                                    >
                                                        Resend OTP
                                                    </button>{" "}
                                                    or
                                                    <button
                                                        className="signup-otp-action"
                                                        onClick={() => setPhoneOtpSent(false)}
                                                    >
                                                        Change phone
                                                    </button>
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        value={formData.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        autoComplete="new-password"
                                        error={!!errors.password}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleTogglePassword} edge="end">
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type={confirmPassword ? "text" : "password"}
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        autoComplete="new-password"
                                        error={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleToggleConfirmPassword} edge="end">
                                                    {confirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" alignItems="center">
                                        <Checkbox
                                            name="termsAndConditions"
                                            value={formData.termsAndConditions}
                                            id="termsAndConditions"
                                            onChange={handleChange}
                                            style={{ paddingLeft: 0 }}
                                            error={!!errors.termsAndConditions}
                                        />
                                        <div>
                                            <label
                                                htmlFor="termsAndConditions"
                                                className="form-label remove-margin-bottom"
                                            >
                                                By Clicking here you agree to our{" "}
                                                <a href="about/terms" target="_blank">
                                                    Terms and Conditions
                                                </a>
                                            </label>
                                            {errors.termsAndConditions && (
                                                <p className="form-error error-message remove-margin">
                                                    {errors.termsAndConditions}
                                                </p>
                                            )}
                                        </div>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!isEmailVerified || !isPhoneVerified }
                                    >
                                        Submit
                                    </Button>

                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
