import sliceCreator from '../index.js';
import {EMPLOYEE_EXPERIENCE} from "../../../constants/index.js"

const _employeeExperienceAction = sliceCreator('EMPLOYEE_EXPERIENCE', EMPLOYEE_EXPERIENCE, 'post');

const { asyncAction: employeeExperienceAction, reducer, clearData: clearemployeeExperienceAction } = _employeeExperienceAction;

export default reducer;

export {
   employeeExperienceAction,
   clearemployeeExperienceAction
}
