import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SideBar from '../../../components/sidebar';
import './styles.css';
import notfound1 from '../../../assets/notfound1.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { employeeSearchAction,clearemployeeSearchAction } from '../../../store/actions';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EmployeeSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { successData,error,errorInfo } = useSelector((state) => state.getEmployeeSearchData);

  const [employeeFound, setEmployeeFound] = useState(true); 

  const formik = useFormik({
    initialValues: {
      panNumber: ''
    },
    validationSchema: Yup.object({
      panNumber: Yup.string()
        .matches(/^[A-Z]{5}\d{4}[A-Z]$/, 'Invalid PAN format')
        .required('PAN number is required')
        .length(10, 'PAN number must be 10 characters')
    }),
    onSubmit: (values) => {
      dispatch(employeeSearchAction({
        endPoint: `searchEmployee/?pan_number=${values.panNumber}`,
      }));
    },
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (formik.isValid) {
        formik.handleSubmit();
      }
    }
  };

  const handleInputChange = (e) => {
    const upperValue = e.target.value.toUpperCase();
    formik.setFieldValue('panNumber', upperValue);
  };


  useEffect(() => {
    if (successData) {
      setEmployeeFound(true);
      toast.success('Employee found successfully');
      navigate('/addToOrganization', { state: { data: successData } });
      setTimeout(() => {
        dispatch(clearemployeeSearchAction());
      }, 100);  
    }
  }, [successData, navigate]);

  useEffect(() => {
    if (error) {
      setEmployeeFound(false);
      toast.error(errorInfo);
    }
  }, [error]);

  return (
    <div style={{ minHeight: '90vh' }}>
      <SideBar />
      {employeeFound ? (
        <div className="employee-container">
          <h1 className="employee-title">Employee Search</h1>
          <form onSubmit={formik.handleSubmit} className="search-box">
            <input
              type="text"
              placeholder="Enter PAN Number..."
              className="search-field"
              name="panNumber"
              value={formik.values.panNumber}
              onBlur={formik.handleBlur}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              maxLength={10}
            />
            {formik.errors.panNumber ? (
              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                {formik.errors.panNumber}
              </div>
            ) : null}
            <button type="submit" className="search-button">
              <SearchIcon />
              Search
            </button>
          </form>
        </div>
      ) : (
        <div
          style={{
            marginLeft: '10%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <img src={notfound1} alt="No Employee Found" style={{ width: '100%', maxWidth: '300px' }} />
          <h3
            style={{
              background: 'linear-gradient(90deg, #2B4257 0%, #625DBD 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            No Results Found
          </h3>
          <h6 style={{ marginLeft: {sm:"0%",md:'0%',xs:'10%'},marginBottom:"2%" }}>

            We couldn't find your employee. Add a new one or Search Again
          </h6>
            <div style={{ display: 'flex', gap: '10px' }}>
              <button
                onClick={() => {
                  navigate('/newEmployee');
                }}
                className="gradient-button"
              >
                Create Employee
              </button>
              <button
                onClick={() => setEmployeeFound(true)}
                className="gradient-button"
              >
                Search Again
              </button>
            </div>
        </div>
      )}
    </div>
  );

};export default EmployeeSearch;
