import { Box } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import SideBar from "../../components/sidebar";
import Grid from "@mui/material/Grid";
import {
  clearMyPartnerActionData,
  myPartnerAction
} from "../../store/actions";
import { canEnableField,dispatchAll } from "../../utils";
import DropdownItems from "../../utils/dropdownutils";
import { moredetails } from "../moredetails/schemaformoredetails";
import "./moredetails.scss";
import {useMediaQuery,Button,TextField,Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Dropdown } from "react-bootstrap";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [partnerData] = React.useState(location.state);
  const {
    successData: ratingData,
    error: ratingError,
    errorInfo: ratingErrorInfo,
  } = useSelector((state) => state.myPartnerData) || {};

  React.useEffect(() => {
    if (ratingData) {
      navigate("/dashboard");
      toast.success("Rating added to customer successfully");
    }
  }, [ratingData,navigate]);

  React.useEffect(() => {
    if (ratingError) {
      toast.error(ratingErrorInfo);
    }
  }, [ratingError,ratingErrorInfo]);
  React.useEffect(() => {
    return () => dispatchAll([clearMyPartnerActionData], dispatch);
  }, [dispatch]);
  const initialValues = {
    score: DropdownItems.score[0],
    totaloutstanding: DropdownItems.totaloutstanding[0],
    ontimepayment: DropdownItems.percentages[5],
    reachability: DropdownItems.reachability[0],
    recoveryprobability: DropdownItems.recoveryprobability[0],
    since: "",
    comments: "",
    dso: DropdownItems.dso[0],
    pendingrecievable: DropdownItems.pendingrecievable[0],
    // gst: {},
    is_gst_paid: {},
    capital: {}, 
    asset: {},
    legal: DropdownItems.legal[0],
    transactionPaidOnTime: {},
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: moredetails,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const formatDate = (dateString) => {
        if (!dateString) return "";
        const [year, month] = dateString.split("-");
        return `${year}-${month}-01`;
      };
      const payload = {
        score: values.score.value,
        total_outstanding: values.totaloutstanding.value,
        on_time_Payment: values.ontimepayment.value,
        reachability: values.reachability.value,
        recovery_probability: values.recoveryprobability.value,
        comments: values.comments,
        dso: values.dso.value,
        is_business_exists: partnerData.is_business_exists,
        known_since: formatDate(values.since),
        pending_receivables: values.pendingrecievable.value,
        business_partner_assoc: partnerData.id,
        
        is_gst_paid: values.is_gst_paid.value,
        capital: values.capital.value,
        assets: values.asset.value,
        legal_proceedings: values.legal.value,
        transaction_paid_ontime: values.transactionPaidOnTime.value,
      };
      payload.known_since === "" && delete payload.known_since;
      dispatch(myPartnerAction(payload));
    },
  });

  return (
   
    <>

    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <SideBar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              ml:{xs:0,sm:0}
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                mt: {sm:'5%',xs:'20%'},
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: {xs:0,sm:3},
                  width: "100%",
                  maxWidth: {xs:'90%',sm:"900px"},
                  minHeight: "100vh",
                }}
              >
                <div style={{ textAlign: "right"}}>
                    <button
                      onClick={() => navigate('/dashboard')}
                      className="back"
                      style={{
                        backgroundColor: "transparent",
                        display: "flex",
                        color: "#2b4257",
                        cursor: "pointer",
                        position: "absolute",
                        right: 12
                      }}
                    >
                      <KeyboardBackspaceIcon /> Back
                    </button>
                  </div>
                  <Grid container spacing={2} style={{justifyContent: "center", alignItems: "center", width: "100%"}}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      component="h5"
                      align="center"
                      fontWeight="bold"
                      mt={{sm:'0%',xs:'10%'}}
                    >
                      Rate My Partner
                    </Typography>
                  </Grid>
                  <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Grid container spacing={2} justifyContent='center'>
                    <Grid container spacing={2} display={"flex"} justifyContent={"center"}>
                      {/* Score Field */}
                      <Grid item xs={12} marginLeft={{xs:'-9%',sm:'0%'}}>
                      <Typography align="center" sx={{ mb: 1 ,color:'#000'}}>
                          Score
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center",minWidth: "300px" }}>
                          <Select
                            value={values.score}
                            name="score"
                            id="score"
                            onChange={(selectedScore) =>
                              setFieldValue("score", selectedScore)
                            }
                            options={DropdownItems.score}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                minWidth: "300px",
                                borderColor:'black'
                              }),
                              menu: (baseStyles) => ({
                                ...baseStyles,
                                width: "300px", 
                                height: "auto",
                                maxHeight: "300px",
                                overflowY: "auto", 
                              }),
                              menuList: (baseStyles) => ({
                                ...baseStyles,
                                maxHeight: "300px",
                              }),
                            }}
                            
                          />
                        </Box>
                        {errors.score && touched.score && (
                          <Typography color="error" align="center" sx={{color:'red'}}>
                            {errors.score.value}
                          </Typography>
                        )}
                      </Grid>
                        {/* Current Outstanding */}
                        <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "totaloutstanding") ? "block" : "none" ,  justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                           ml: { xs: -2.5, sm: 0 },
                        }}>
                          <Typography color='#000'>Current Outstanding</Typography>
                          <Select
                            value={values.totaloutstanding}
                            name="totaloutstanding"
                            id="totaloutstanding"
                            onChange={(selectedOption) => setFieldValue("totaloutstanding", selectedOption)}
                            options={DropdownItems.totaloutstanding}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor: "black",
                              }),
                            }}
                          />
                          {errors.totaloutstanding && touched.totaloutstanding && (
                            <Typography color="error">{errors.totaloutstanding?.value || errors.totaloutstanding}</Typography>
                          )}
                        </Grid>
    
                        {/* Days Since Outstanding */}
                        <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4}sx={{ display: canEnableField(values.score, "dso") ? "block" : "none" ,justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                          ml: { xs: -2.5, sm: 0 },
                        }}>
                          <Typography color='#000'>Days Since Outstanding</Typography>
                          <Select
                            value={values.dso}
                            name="dso"
                            id="dso"
                            onChange={(selectedOption) => setFieldValue("dso", selectedOption)}
                            options={DropdownItems.dso}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor: "black",
                              }),
                            }}
                          />
                          {errors.dso && touched.dso && (
                            <Typography color="error">{errors.dso?.value || errors.dso}</Typography>
                          )}
                        </Grid>
    
                        {/* Pending Receivable */}
                        <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "pendingrecievable") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                         ml: { xs: -2.5, sm: 0 },
                         }}>
                          <Typography color='#000'>Pending Receivable</Typography>
                          <Select
                            value={values.pendingrecievable}
                            name="pendingrecievable"
                            id="pendingrecievable"
                            onChange={(selectedOption) => setFieldValue("pendingrecievable", selectedOption)}
                            options={DropdownItems.pendingrecievable}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor:'black'
                              }),
                            }}
                          />
                          {errors.pendingrecievable && touched.pendingrecievable && (
                            <Typography color="error">{errors.pendingrecievable?.value || errors.pendingrecievable}</Typography>
                          )}
                        </Grid>
    
                        {/* Pending Since */}
                        <Grid item xs={12}   sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "since") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                          ml: { xs: -2.5, sm: 0 },
                         }}>
                          <Typography color='#000'>Pending Since</Typography>
                          <TextField
                            fullWidth
                            type="month"
                            name="since"
                            id="since"
                            value={values.since}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              inputProps: {
                                max: new Date().toISOString().split("-").slice(0, 2).join("-"),
                              }, 
                                style: {  height: '38px'},
                            }}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                              borderColor:'black',
                              })
                            }}
                            error={Boolean(errors.since && touched.since)}
                            helperText={errors.since && touched.since ? errors.since : ""}
                          />
                        </Grid>
    
                        {/* Legal Proceedings */}
                        <Grid item xs={12}   sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "legal") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                         ml: { xs: -2.5, sm: 0 },
                         }}>
                          <Typography color='#000'>Legal Proceedings</Typography>
                          <Select
                            value={values.legal}
                            name="legal"
                            id="legal"
                            onChange={(selectedOption) => setFieldValue("legal", selectedOption)}
                            options={DropdownItems.legal}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor: "black",
                              }),
                            }}
                          />
                          {errors.legal && touched.legal && (
                            <Typography color="error">{errors.legal?.value || errors.legal}</Typography>
                          )}
                        </Grid>
    
                        {/* Reachability */}
                        <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "reachability") ? "block" : "none",justifyContent:'center',
                          ml: { xs: -2.5, sm: 0 },
                        }}>
                          <Typography color='#000'>Reachability</Typography>
                          <Select
                            value={values.reachability}
                            name="reachability"
                            id="reachability"
                            onChange={(selectedOption) => setFieldValue("reachability", selectedOption)}
                            options={DropdownItems.reachability}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor: "black",
                              }),
                            }}
                          />
                          {errors.reachability && touched.reachability && (
                            <Typography color="error">{errors.reachability?.value || errors.reachability}</Typography>
                          )}
                        </Grid>
    
                        {/* Recovery Probability */}
                        <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "recoveryprobability") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                           ml: { xs: -2.5, sm: 0 },
                         }}>
                          <Typography color='#000'>Recovery Probability</Typography>
                          <Select
                            value={values.recoveryprobability}
                            name="recoveryprobability"
                            id="recoveryprobability"
                            onChange={(selectedOption) => setFieldValue("recoveryprobability", selectedOption)}
                            options={DropdownItems.recoveryprobability}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor: "black",
                              }),
                            }}
                          />
                          {errors.recoveryprobability && touched.recoveryprobability && (
                            <Typography color="error">{errors.recoveryprobability?.value || errors.recoveryprobability}</Typography>
                          )}
                        </Grid>
    
                        {/* On-time Payment */}
                        <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{    display: canEnableField(values.score, "percentages") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                          ml: { xs: -2.5, sm: 0 },
                         }}>
                          <Typography color='#000'>On-time Payment</Typography>
                          <Select
                            
                            value={values.ontimepayment}
                            name="ontimepayment"
                            id="ontimepayment"
                            onChange={(selectedOption) => setFieldValue("ontimepayment", selectedOption)}
                            options={DropdownItems.percentages}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                width: "100%",
                                maxWidth: "300px",
                                borderColor: "black",
                              }),
                            }}
                          />
                          {errors.ontimepayment && touched.ontimepayment && (
                            <Typography color="error">{errors.ontimepayment?.value || errors.ontimepayment}</Typography>
                          )}
                        </Grid>
                        </Grid>
                    
                    {/* Comments */}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft:{xs:'2%',sm:'15%'},
                            alignItems: values.score && values.score.value >= 9 ? 'center' : 'start',
                            justifyContent: values.score && values.score.value >= 9 ? 'center' : 'flex-start',
                            minWidth: {xs:290,sm:600},
                            margin: 'auto',
                          }}
                        >
                          <Typography
                            component="label"
                            htmlFor="comments"
                            sx={{ marginTop: 2, fontSize: '1rem', fontWeight: 'bold' }}
                          >
                            Comments
                          </Typography>
                          <TextField
                            type="text"
                            name="comments"
                            value={values.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="comments"
                            placeholder="Comments"
                            multiline
                            minRows={1}
                            maxRows={6}
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                  height: 'auto'    
                              }
                            }}
                            error={Boolean(errors.comments && touched.comments)}
                            inputProps={{ maxLength: 300 }}
                            helperText={
                              errors.comments && touched.comments ? (
                                <Typography sx={{ color: 'red', fontSize: '0.875rem' }}>{errors.comments}</Typography>
                              ) : null
                            }
                          />
                        </Box>
                      {/* Submit Button */}
                      <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              background:
                                "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                            }}
                          >
                            Submit
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        </>
   
  );
};

export default Index;
