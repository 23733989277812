import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableData from "../../utils/tableutils";
import Table from "../../components/table";
import { Modal } from "react-bootstrap";
import SideBar from "../../components/sidebar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Loader from "../../components/loader";
import { getRaiseDispute, putResolvedDispute } from "../../store/actions";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
import PropTypes from 'prop-types';
import "./raise.scss";

const DisputeContent = ({
  loadingRaisedDispute,
  isSelectedResolvedDispute,
  raisedOnYou,
  raisedByYou,
  resolvedDisputeColumns,
  columns,
  onClickEditDetails
}) => {
  if (loadingRaisedDispute) {
    return <Loader />;
  }

  if (isSelectedResolvedDispute) {
    return raisedOnYou.length > 0 ? (
      <Table
        columns={resolvedDisputeColumns}
        data={raisedOnYou}
        onClickEditDetails={onClickEditDetails}
      />
    ) : (
      <h3 style={{ fontSize: '1.5rem' ,color:'#2b4257'}}>No Disputes Raised on You</h3>
    );
  }

  return raisedByYou.length > 0 ? (
    <Table columns={columns} data={raisedByYou} />
  ) : (
    <h3 style={{ fontSize: '1.5rem',color:'#2b4257' }}>No Disputes Raised by You</h3>
  );
};


const RaiseComponent = () => {
  const dispatch = useDispatch();
  const [raisedByYou, setRaisedByYou] = useState([]);
  const [raisedOnYou, setRaisedOnYou] = useState([]);
  const [isSelectedResolvedDispute, setIsSelectedResolvedDispute] = useState(false);
  const [selectedRaisedUsername, setSelectedRaisedUsername] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [reasonText, setReasonText] = useState("");

  const { successData: putResolvedData } = useSelector((state) => state.putResolvedDispute) || {};
  const { loading: loadingRaisedDispute, successData: disputesData } = useSelector((state) => state.getRaiseDispute) || {};

  const columns = React.useMemo(() => TableData.raisedByYou, []);
  const resolvedDisputeColumns = React.useMemo(() => TableData.raisedOnYou, []);

  const fetchDisputes = useCallback(() => {
    dispatch(getRaiseDispute());
  }, [putResolvedData,dispatch]);

  useEffect(() => {
    fetchDisputes();
  }, [fetchDisputes]);

  useEffect(() => {
    if (disputesData) {
      setRaisedByYou(disputesData.raisedByYou || []);
      setRaisedOnYou(disputesData.raisedOnYou || []);
    }
  }, [disputesData]);

  useEffect(() => {
    if (putResolvedData) {
      setRaisedOnYou((prevRaisedOnYou) => {
        const updatedRaisedOnYou = [...prevRaisedOnYou];
        // Update raisedOnYou state as needed
        return updatedRaisedOnYou;
      });
      setReasonText("");
      setShowModal(false);
    }
  }, [putResolvedData]);

  const onClickEditDetails = (rowIndex) => {
    const selectedResolvedDispute = disputesData.raisedOnYou[rowIndex];
    const selectedRaisedUsername = selectedResolvedDispute
      ? selectedResolvedDispute.user.first_name
      : "";
    setSelectedIndex(rowIndex);
    setSelectedRaisedUsername(selectedRaisedUsername);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedIndex(null);
  };

  const onSubmitResolvedData = () => {
    if (!reasonText) {
      toast.error("Please enter a reason before submitting.");
      return;
    }

    setShowModal(false);
    const data = {
      reason: reasonText,
      user: raisedOnYou[selectedIndex]?.user.id
    };
    dispatch(putResolvedDispute({ endPoint: `raiseDispute/${raisedOnYou[selectedIndex].id}/`,...data}));
    setReasonText("");
  };


  return (
    <>
      <Box sx={{ display: "flex", minHeight: "90vh" }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }} mt={8}>
          <Grid container>
            <Grid container item xs={12} mt={2} ml={2}>
              <Grid container spacing={2}>
              <Grid container item xs={12} mt={2} ml={2} direction="row" justifyContent="space-between" alignItems="center"
                 sx={{flexWrap: { xs: 'nowrap', sm: 'wrap' }}}
               >
                  <Typography
                    className="Myratingsheading"
                    sx={{
                      marginLeft: { xs: '-8%', sm: '0' },
                      marginBottom: { xs: '0.5rem', sm: '1rem' },
                      lineHeight: 1.2,
                      fontSize: { xs: '1.75rem', sm: '2.5rem' }
                    }}
                  >
                    Disputes
                  </Typography>
                  <p
                    className="numberofdisputes"
                    style={{
                      margin: 0,
                      width: { xs: 'auto', sm: 'auto' },
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      whiteSpace: 'nowrap',
                      marginBottom: { xs: '0.5rem', sm: '1rem' },
                      lineHeight: 1.2,
                      padding: { xs: '0rem', sm: '0.5rem' },
                    }}
                  >
                    {window.innerWidth <= 600 ? "No. of Disputes: " : "Number of Disputes: "}{raisedByYou.length}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={1} ml={2}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Tabs
                    value={isSelectedResolvedDispute ? 1 : 0}
                    onChange={(event, newValue) => setIsSelectedResolvedDispute(newValue === 1)}
                    indicatorColor="primary"
                    textColor="primary"
                    orientation="vertical"
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        flexDirection: {
                          xs: 'column',
                          sm: 'row'
                        },
                        gap:'10px',
                      }
                    }}
                  >
                        <Tab label="Disputes Raised by You" sx={{border: '1px solid #2b4257',}}/>
                    <Tab label="Disputes Raised on You" sx={{border: '1px solid #2b4257',}}/>
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12} ml={2} mt={2}>
              <DisputeContent
                loadingRaisedDispute={loadingRaisedDispute}
                isSelectedResolvedDispute={isSelectedResolvedDispute}
                raisedOnYou={raisedOnYou}
                raisedByYou={raisedByYou}
                resolvedDisputeColumns={resolvedDisputeColumns}
                columns={columns}
                onClickEditDetails={onClickEditDetails}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className="model_resolve_dispute">
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Resolve Dispute of <strong>{selectedRaisedUsername}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              label="Reason"
              multiline
              rows={6}
              fullWidth
              name="reason"
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
            />
            <div className="raisedbuttoncontainer">
              <button
                className="btn btn-primary"
                type="button"
                onClick={onSubmitResolvedData}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
export default RaiseComponent;

DisputeContent.propTypes = {
  loadingRaisedDispute: PropTypes.bool.isRequired,
  isSelectedResolvedDispute: PropTypes.bool.isRequired,
  raisedOnYou: PropTypes.array.isRequired,
  raisedByYou: PropTypes.array.isRequired,
  resolvedDisputeColumns: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onClickEditDetails: PropTypes.func.isRequired
};