import React from 'react';
import Grid from "@mui/material/Grid";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Sidebar from '../EmployeeSidebar/Sidebar';


function Employee() {
    return (
        <Box pl={3} marginTop={4} pb={4}>
            <Grid container spacing={2} style={{ overflowX: 'hidden' }}>
                <Grid item xs={2} sm={2} md={2} lg={1} >
                    <Sidebar />
                </Grid>
                <Grid item xs={8} sm={10} md={10} marginTop={5}>
                    <Outlet />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Employee;
