import sliceCreator from '../index.js';
import {EMPLOYEE_RATING} from "../../../constants/index.js"

const _getEmployeeRatingAction = sliceCreator('EMPLOYEE_RATING', EMPLOYEE_RATING, 'get');

const { asyncAction: getEmployeeRatingAction, reducer, clearData: cleargetEmployeeRatingAction } = _getEmployeeRatingAction;

export default reducer;

export {
    getEmployeeRatingAction,
    cleargetEmployeeRatingAction
}
