import React, { useEffect, useState } from 'react'
import { Box, Tabs, Tab, styled, CircularProgress } from '@mui/material'
import SideBar from "../../../components/sidebar";
import EmployeeListCard from './EmployeeListCard'
import { useSelector, useDispatch } from 'react-redux';
import { employeeDashboardAction } from '../../../store/actions';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  '& .MuiTab-root': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      minWidth: 'auto',
      padding: '6px 8px',
    }
  }
}));

function EmployeeDashboard() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { loading, successData: employeeList } = useSelector((state) => state.getEmployeeDashboardData);
  const [verifySubTab, setVerifySubTab] = useState(0);

  useEffect(() => {
    dispatch(employeeDashboardAction());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleVerifySubTabChange = (event, newValue) => {
  setVerifySubTab(newValue);
};

  const getActiveEmployees = () => {
    switch(activeTab) {
      case 0:
        return employeeList?.present_employees;
      case 1:
        return employeeList?.previous_employees;
        case 2:
          return verifySubTab === 0 
            ? employeeList?.verification?.verification_pending_employees 
            : employeeList?.verification?.transitional_employees;
        default:    
        return [];
    }
  };

  return (
    <>
      <SideBar />
      <Box mt={{ xs: '20%', sm: '10%' }} mb={{ xs: '30%', sm: '15%' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
              <StyledTabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Present Employees" />
                <Tab label="Previous Employees" />
                <Tab label="Verify Employees" />
              </StyledTabs>
            </Box>

            {activeTab === 2 && (
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                <StyledTabs value={verifySubTab} onChange={handleVerifySubTabChange}>
                  <Tab label="Pending Verification" />
                  <Tab label="Transitional Employees" />
                </StyledTabs>
              </Box>
            )}

            <EmployeeListCard employees={getActiveEmployees()} 
            isTransitional={activeTab === 2 && verifySubTab === 1}
            />
          </>
        )}
      </Box>
    </>
  );
}

export default EmployeeDashboard
