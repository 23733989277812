import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../components/Error/Error";
import Backdrop from "../../components/backdrops/Backdrop";
import {
  clearGetGstDetailsAction,
  getGstDetailsAction,
} from "../../store/actions";
import { dispatchAll } from "../../utils";
import GstRegistration from "./GstRegistration";
import Gstdata from "./Gstdata";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const GSTCard = ({ handleFormChange , setOpenGSTDialog}) => {
  const GST_REGEX = /^\d{2}[A-Z]{5}\d{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/;
  const [gst, setGst] = useState("");
  const [error, setError] = useState("");
  const [gstData, setGstData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (event) => {
    setGst(event.target.value);
    if (!GST_REGEX.test(event.target.value)) {
      setError("Invalid GST number");
    } else {
      setError("");
    }
  };
  const handleLogin = () => {
    navigate("/Login");
  };
  const {
    loading: isLoading,
    successData: data,
    error: err,
    errorInfo,
  } = useSelector((state) => state.gstdetailsData) || {};

  const handleSubmit = () => {
    const localStoragePromise = new Promise((resolve, reject) => {
      try {
        localStorage.setItem("gst", gst);
        resolve();
      } catch (error) {
        toast.error(errorInfo)
      }
    });

    localStoragePromise
      .then(() => {
        dispatch(getGstDetailsAction());
      })
      .catch((error) => {
        console.error("Error setting GST in localStorage:", error);
      });
  };

  useEffect(() => {
    dispatchAll([clearGetGstDetailsAction], dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const gstData = Array.isArray(data) ? data[0] : data;
      setGstData(gstData);
    }
  }, [data]);

  useEffect(() => {
    if (err) {
      toast.error(errorInfo);
      dispatch(clearGetGstDetailsAction());
    }
  }, [err, dispatch, errorInfo]);
  useEffect(() => {
    if (data) {
      const gstData = Array.isArray(data) ? data[0] : data;
      if (gstData?.is_user_exists) {
        setOpenGSTDialog(true);
      } else {
        setGstData(gstData);
      }
    }
  }, [data, setOpenGSTDialog]);
  return (
    <Grid container justifyContent={"center"} alignContent={"center"}>
      <Grid item justifySelf={"center"}>
        {gstData?.is_user_exists && (
          <Error message="User already exists" backgroundColor="red" />
        )}
        {isLoading && <Backdrop />}
        {!gstData?.fullname ? (
          <GstRegistration
            handleFormChange={handleFormChange}
            gst={gst}
            handleChange={handleChange}
            error={error}
            handleSubmit={handleSubmit}
            handleLogin={handleLogin}
            isUserExists={gstData?.is_user_exists || false} 
          />
        ) : (
          <Gstdata data={gstData} />
        )}
      </Grid>
    </Grid>
  );
};

export default GSTCard;

GSTCard.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  setOpenGSTDialog: PropTypes.func.isRequired,
};
