import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";


import Noresultssearch from "./screens/Noresultssearch";
import Partnerdetails from './screens/Partnerdetails';
import Raisedispute from './screens/Raisedispute';
import Subscription from './screens/Subscription';
import Dashboard from "./screens/dashboard";
import Footer from './screens/footer';
import Forgotpassword from "./screens/forgotpassword";
import Linktocustomer from "./screens/linktocustomer";
import Login from './screens/login';
import Moredetails from './screens/moredetails';
import Myratings from './screens/myratings';
import Newcustomer from './screens/newcustomer';
import Resetpassword from "./screens/resetpassword";
import Searchprofile from "./screens/searchprofile";
import Register from './screens/signup';
import PrivateRoute from './components/hoc/sessionValidator';
import AdminRoute from './components/hoc/AdminProtectedRoute';
import Newpassword from './screens/Newpassword';
import Paymentsuccess from './screens/Paymentsuccess';
import Editviewrating from "./screens/editandviewrating";
import Payment from './screens/payment';
import About from './screens/termsandcondition/About';
import CompanyName from './screens/termsandcondition/companyname';
import ContactUs from './screens/termsandcondition/contactus';
import Privacy from './screens/termsandcondition/privacy';
import Refund from './screens/termsandcondition/refund';
import TermsConditions from './screens/termsandcondition/terms';
import Shipping from './screens/termsandcondition/shipping'
import PricingPolicy from './screens/termsandcondition/PricingPolicy';
import Admin from "./screens/adminScreens/Admin";
import SearchWithPAN from './screens/adminScreens/searchWithPAN';
import ActiveUserList from './screens/adminScreens/InactiveUserList';
import SearchWithDB from './screens/adminScreens/searchWithDB';
import Payments from './screens/adminScreens/payments';
import Admindashboard from './screens/adminScreens/dashboard';
import Profile from './screens/profile';
import PageNotFound from './screens/PageNotFound';
import UserPayment from './screens/adminScreens/payments/payment';
import RelatedPANDetails from './screens/adminScreens/reletedpan';
import UserRatingDetails from './screens/adminScreens/searchWithDB/userRatingDetails';
import Consent from './screens/termsandcondition/Consent';
import EmployeeSearch from './screens/EmployeeRating/employeeSearch';
import EmployeeDashboard from './screens/EmployeeRating/EmployeeList/EmployeeDashboard';
import EmployeeDetails from './screens/EmployeeRating/EmployeeList/EmployeeDetails';
import EmployeeEdit from './screens/EmployeeRating/EmployeeList/EmployeeEdit';
import NewEmployee from './screens/EmployeeRating/employeeSearch/NewEmployee';
import AddToOrganization from './screens/EmployeeRating/employeeSearch/AddToOrganization';
import MainHomepage from './screens/Homepage/MainHomePage';
import BulkRating from './screens/bulkRating';
import EmployeeRegistration from './screens/EmployeeScreens/EmployeeRegistration';
import Sidebar from './screens/EmployeeScreens/EmployeeSidebar/Sidebar';
import Employee from './screens/EmployeeScreens/Employee/index';
import EmployeeProfile from './screens/EmployeeScreens/EmployeeProfile/EmployeeProfile';
import EmployeesDashboard from './screens/EmployeeScreens/EmployeesDashboard/EmployeesDashboard';
import EmployeeExist from "./screens/EmployeeRating/EmployeeList/EmployeeExistVerify"
import AddEmployeeExperience from './screens/EmployeeRating/EmployeeList/AddEmployeeExperience';

function App() {
      return (
        <BrowserRouter>
          <Routes>                        
              <Route exact path="/login" element={<Login/>} />    
              <Route path='/' element={<MainHomepage/>}></Route>
              <Route path="/register" element={<Register/>} />      
              <Route path="/dashboard" element={<PrivateRoute component={Dashboard}/>} />  
              {/* <Route path="/dashboard" element={<Login/>} />   */}
              <Route path="/linktocustomer" element={<PrivateRoute component={Linktocustomer}/>} /> 
              <Route path="/searchprofile" element={<PrivateRoute component={Searchprofile}/>} /> 
              <Route path='/forgotpassword' element={<Forgotpassword />}></Route>
              <Route path='/resetpassword' element={<Resetpassword/> }></Route>
              <Route path='/forgotpassword' element={<Forgotpassword />}></Route>
              <Route path='/resetpassword' element={<Resetpassword/> }></Route>
              <Route path='/noresultssearch' element={<PrivateRoute component={Noresultssearch}/>}></Route>
              <Route path='/newcustomer' element={ <PrivateRoute component={Newcustomer}/> }></Route>
              <Route path='/moredetails' element= {<PrivateRoute component={Moredetails}/>}></Route>
              <Route path='/myratings' element={ <PrivateRoute component={Myratings}/> }></Route>
              <Route path='/partnerdetails' element={<PrivateRoute component={Partnerdetails}/>}></Route>
              <Route path='/disputes' element={<PrivateRoute component={Raisedispute}/>}></Route>
              <Route path='/profile' element={<PrivateRoute component={Profile}/>}></Route> 
               <Route path='/subscription' element={<Subscription/>}></Route>
               <Route path='/newpassword' element={<Newpassword/>}></Route>
               <Route path='/payment' element={<Payment/>}></Route>


               <Route path='/paymentsuccess' element={<Paymentsuccess/>}></Route>
               <Route path='/editviewrating' element={<PrivateRoute component={Editviewrating}/>}></Route>
               <Route path='/companyname' element={< CompanyName/>}></Route>
               <Route path='/privacy' element={< Privacy/>}></Route>
               <Route path='/refund' element={< Refund/>}></Route>
               <Route path='/employeeSearch' element={< EmployeeSearch/>}></Route>
               <Route path='/employeeDashboard' element={< EmployeeDashboard/>}></Route> 
               <Route path='/employeeDetails' element={< EmployeeDetails/>}></Route>
               <Route path='/employeeEdit' element={< EmployeeEdit/>}></Route>
               <Route path='/newEmployee' element={< NewEmployee/>}></Route>
               <Route path='/employeeRegistration' element={< EmployeeRegistration/>}></Route>
               <Route path='/verifyEmployee' element={< EmployeeExist/>}></Route>
               <Route path='/addemployeeExperience' element={< AddEmployeeExperience/>}></Route>
               <Route path='/sidebar' element={< Sidebar/>}></Route>
               <Route path='/addToOrganization' element={< AddToOrganization/>}></Route>
               <Route path='/bulkRating' element={< BulkRating/>}></Route>
               
           <Route path='/admin' element={<Admin />}>
          <Route path="" element={<Navigate to="dashboard" />} />
          <Route path='dashboard' element={<AdminRoute component={Admindashboard} />} />
          <Route path='searchWithPAN' element={<AdminRoute component={SearchWithPAN} />} />
          <Route path='activeUserList' element={<AdminRoute component={ActiveUserList} />} />
          <Route path='Payments' element={<AdminRoute component={Payments} />} />
          <Route path='DbSearch' element={<AdminRoute component={SearchWithDB} />} />
          <Route path='UserPayment/:id' element={<AdminRoute component={UserPayment} />} />
          <Route path='relatedpan' element={<AdminRoute component={RelatedPANDetails} />} />
          <Route path='DbSearch/userRatingDetails' element={<AdminRoute component={UserRatingDetails} />} />
           </Route>

           <Route path='/employee' element={<Employee />}>
              <Route path='' element={<Navigate to="dashboard" />} />
              <Route path='dashboard' element={<EmployeesDashboard/>}/>
              <Route path='profile' element={<EmployeeProfile />} />
           </Route>

               <Route path='/about' element={<About/>}>
               
          <Route path='terms' element={<TermsConditions/>}/>
          <Route path='contact' element={<ContactUs/>}/>
          <Route path='refund' element={<Refund/>}/>
          <Route path='privacy' element={<Privacy/>}/>
        <Route path='shipping' element={<Shipping/>}/>
        <Route path='pricing' element={<PricingPolicy/>}/>
        <Route path='consent' element={<Consent/>}/>
            </Route>
            <Route path="*" element={<PageNotFound/>}/>
          </Routes>
          <Footer />
        </BrowserRouter>
        
      );
    }
  
  export default App;
  