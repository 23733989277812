import React, { useState } from "react";
import "./Partnerdetails.scss";
import * as Yup from "yup";
import SideBar from "../../components/sidebar";
import { Formik, Form, Field } from "formik";
import PeopleIcon from "@mui/icons-material/People";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getMyPartnerRatingsAction,
  clearGetMyPartnerRatingsActionData, postLinkedReationActon
} from "../../store/actions";
import { toast } from "react-toastify";
import { dispatchAll } from "../../utils";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, } from 'recharts';
import Ratings from "../../components/ratings";

import { Dialog, DialogContent, Button, Typography, IconButton, Box, Grid, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Comments from "../../components/comments";
import OutstandingLoans from "../../components/outstandings";
import PendingReceivables from "../../components/pendingReceivables";
import LegalProceedings from "../../components/legalProceedings";
import Reachability from "../../components/reachability";
import RecoveryProbability from "../../components/recoveryProbability";


const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [show, setShow] = useState(false);

  const [selectedAssocPartner] = React.useState(
    location.state?.selectedAssocPartner || {
      business_partner_id: '',
      partner_business_name: '',
      business: { name: '' }
    }
  );

  const [partnersRatings, setPartnersRatings] = React.useState([]);
  const [overallDetails, setOverallDetails] = React.useState({
    overAllRatings: {
      1: 0.0,
      2: 0.0,
      3: 0.0,
      4: 0.0,
      5: 0.0,
      6: 0.0,
      7: 0.0,
      8: 0.0,
      9: 0.0,
      10: 0.0,
    },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });
  const { successData: PartnerRatingData, error, errorInfo } = useSelector((state) => state.getMyPartnerRatingsData) || {};
  const business_partner_assoc_id = selectedAssocPartner.business_partner_id || selectedAssocPartner.id;
  const isMobile = useMediaQuery("(max-width: 767px)");
  const [outStanding, setOutStanding] = React.useState("");
  const [pendingReceivables, setPendingReceivables] = React.useState("");
  const [pendingCount, setPendingCount] = React.useState(0);
  const [totalCustomer, setTotalCustomer] = React.useState(0);
  const [dsoAverageDays, setDsoAverageDays] = React.useState(0);
  const [legalProceedings, setLegalProceedings] = React.useState(0);
  const [reachability, setReachability] = React.useState(0);
  const [avgRating, setAvgRating] = React.useState(0);
  const [reachabilityCustomerCount, setReachabilityCustomerCount] =
    React.useState(0);
  const [comments, setComments] = React.useState([
    {
      heading: "",
      comment: "",
    },
  ]);
  const [recoveryProbability, setRecoveryProbability] = React.useState([
    {
      yes: 0,
      yes_with_multiple_attempts: 0,
      no: 0,
      difficult: 0
    },
  ]);

  React.useEffect(() => {
    return () => dispatchAll([clearGetMyPartnerRatingsActionData], dispatch);
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(
      getMyPartnerRatingsAction({
        endPoint: `getPartnerRatings/?partner_id=${business_partner_assoc_id}`,
      })
    );
  }, [dispatch, business_partner_assoc_id]);

  React.useEffect(() => {
    if (PartnerRatingData) {
      setComments(PartnerRatingData.all_comments);
      setOutStanding(PartnerRatingData.outstanding);
      setPendingReceivables(PartnerRatingData.pending_receivables);
      setPendingCount(PartnerRatingData.pending_count);
      setTotalCustomer(PartnerRatingData.total_customers);
      setDsoAverageDays(PartnerRatingData.dso_average_days);
      setReachability(PartnerRatingData.reachability_percentage);
      setReachabilityCustomerCount(PartnerRatingData.reachability_customer_count);
      setLegalProceedings(PartnerRatingData.no_of_legal_proceeding);
      setRecoveryProbability(PartnerRatingData.recovery_probability_counts);
      setPartnersRatings(PartnerRatingData.num_of_ratings);
      setOverallDetails(PartnerRatingData.overall_ratings);
      setAvgRating(PartnerRatingData.avg_rating.toFixed(1));
    }
  }, [PartnerRatingData]);

  React.useEffect(() => {
    if (error) {
      toast.success(errorInfo);
    }
  }, [error, errorInfo]);

  const handleClose = () => {
    setShow(false);
  };

  const familyRelations = [
    { value: "Spouse", label: "Spouse" },
    { value: "Partner/Director", label: "Partner/Director" },
    { value: "Son", label: "Son" },
    { value: "Daughter", label: "Daughter" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
    { value: "Sibling", label: "Sibling" },
    { value: "Grandparent", label: "Grandparent" },
    { value: "Aunt/Uncle", label: "Aunt/Uncle" },
    { value: "Cousin", label: "Cousin" },
    { value: "In-Law", label: "In-Law" }
  ];


  const validationSchema = Yup.object({
    panno: Yup.string().required("PAN No. is required")
      .matches(/^[A-Z]{5}\d{4}[A-Z]$/, 'Invalid PAN number'),
    confirmpanno: Yup.string()
      .oneOf([Yup.ref('panno'), null], 'PAN numbers must match')
      .required("Please confirm your PAN number"),
    name: Yup.string().required("Name is required"),
    relation: Yup.string().required("Relation is required"),
  });
  const initialValues = {
    panno: "",
    name: "",
    relation: "",
    confirmpanno: "",
  };

  const handleSubmit = async (values, actions) => {
    try {
      await dispatch(
        postLinkedReationActon({
          user_id_main: business_partner_assoc_id,
          user_pan_assoc: values.panno,
          relationship: values.relation,
          name: values.name,

        })
      ).unwrap();
      actions.resetForm();
      toast.success("Successfully requested to add a relation to your partner.")
      setShow(false)
    } catch (err) {
      toast.error(err.message || 'An error occurred');
    }

  };
  const handleChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value.toUpperCase());
  };

  const avgRatingdata = PartnerRatingData?.avg_rating_trend;
  // Replace the existing formattedData definition with:
  const formattedData = avgRatingdata?.map(item => ({
    date: new Date(item.date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    }),
    time: new Date(item.date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    }),
    fullDateTime: new Date(item.date), // Store full date time for sorting
    avg_score: item.avg_score
  })).sort((a, b) => {
    return b.fullDateTime - a.fullDateTime;  // This will sort by both date and time
  });




  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ width: "88px", height: "40px", backgroundColor: 'white', padding: '1px', border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <p style={{ fontSize: "10px", margin: "1px" }} >{`Date: ${payload[0].payload.date}`}</p>
          <p style={{ fontSize: "10px", margin: "1px" }}>{`Avg Score: ${payload[0].payload.avg_score.toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <SideBar />
        <Box component="main" sx={{ flexGrow: "1", p: { xs: '0px', md: 3 } }} mt={8}>
          {/* Header Section */}
          <Grid container alignItems="center" spacing={2} mb={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{
                fontSize: { xs: "22px", md: "30px" },
                fontWeight: "bold"
              }}>
                Rating Details of{" "}
                <span className="customer-name">
                  {selectedAssocPartner.partner_business_name || selectedAssocPartner.business.name}
                </span>
              </Typography>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6} mb={{xs:5,sm:0}}>
            <div style={{ textAlign: "right"}}>
                <button
                  onClick={() => navigate('/dashboard')}
                  className="back"
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    color: "#2b4257",
                    cursor: "pointer",
                    position: "absolute",
                    right: 12,
                  }}
                >
                  <KeyboardBackspaceIcon /> Back
                </button>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={3}>
            {[
              { icon: <PeopleIcon />, text: `No. of Ratings Received : ${PartnerRatingData?.ratings_received}`, className: "cardone" },
              { text: `No. of Ratings Given : ${PartnerRatingData?.ratings_given}`, className: "card2" },
              { text: `Total Number of Disputes : ${PartnerRatingData?.dispute_count}`, className: "card3" }
            ].map((card, index) => (
              <Grid item xs={11} md={4} key={index}>
                <Box className={card.className} sx={{
                  p: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}>
                  {card.icon}
                  {card.text}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3}>
            {/* Rating Card Section */}
            <Grid item xs={11} md={4}>
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                borderRadius: "15px",
                margin: "0 auto",
                backgroundColor: "white",
                maxWidth: { xs: "100%", md: "400px" },
                minWidth: { xs: "100%", md: "300px" },
                marginLeft: { xs: "0", md: "-12%" },
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: 2,
              }}
              >
                <Ratings
                  overallDetails={overallDetails}
                  avgRating={avgRating}
                  partnerName={`for ${selectedAssocPartner.partner_business_name}`}
                  partnersRatings={partnersRatings}
                />
              </Grid>

              {/* Add Related PAN Section */}
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={11} sm={10} style={{ marginTop: '10%' }}>
                  <div style={{
                    textAlign: "center",
                    justifyContent: "center",
                    gap: "10px",
                    alignItems: "center",
                  }}>
                    <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                      Add related PAN of{" "}
                      <span className="customer-name">
                        {selectedAssocPartner.partner_business_name}
                      </span>{" "}
                      ?
                    </p>
                    <Button
                      type="button"
                      onClick={() => setShow(true)}
                      variant="contained"
                      sx={{
                        marginBottom: '15px',
                        background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#2b4257",
                          color: "#fff",
                        },
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>            {/* Charts and Details Section */}
            <Grid item xs={11} md={8}>
              <Grid container spacing={2}>
                {/* Line Chart */}
                <Grid item xs={12}>
                  <Box sx={{
                    backgroundColor: "white",
                    borderRadius: 2,
                    p: 3,
                    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  }}>
                    {formattedData && formattedData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          data={[...formattedData].reverse()} // Reverse the data array
                          margin={{ top: 20, right: 30, left:isMobile? -5 : 20, bottom: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            orientation="bottom"

                          />
                          <YAxis
                            domain={[0, 10]}
                            ticks={[0, 5, 10]}
                            orientation="left"
                            yAxisId="left"
                          />
                          <Tooltip content={<CustomTooltip />} />
                          <Line
                            type="monotone"
                            dataKey="avg_score"
                            stroke="#00C49F"
                            strokeWidth={2}
                            dot={{ r: 3, fill: '#00C49F' }}
                            yAxisId="left"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography sx={{
                        textAlign: 'center',
                        fontSize: '16px',
                        color: '#2b4257',
                      }}>
                        No ratings available to display.
                      </Typography>
                    )}
                  </Box>
                </Grid>

                {/* Stats Components */}
                <Grid item xs={12}>
                  <PendingReceivables
                    pendingReceivables={pendingReceivables}
                    totalCustomer={pendingCount}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <OutstandingLoans
                    outstanding={outStanding}
                    totalCustomer={totalCustomer}
                    pendingCount={pendingCount}
                    dsoAverageDays={Math.round(dsoAverageDays)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <RecoveryProbability
                    recoveryProbability={recoveryProbability}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <LegalProceedings
                    legalProceedings={legalProceedings}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Reachability
                    reachability={reachability}
                    customers={reachabilityCustomerCount}
                  />
                </Grid>

                {/* Comments Section */}
                <Grid item xs={12}>
                  <Box sx={{
                    backgroundColor: "white",
                    borderRadius: 2,
                    p: 3,
                    boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                    wordBreak: "break-word",
                    lineHeight: "1.5",
                  }}>
                    <Comments comments={comments} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
            <Box position="relative" sx={{ p: 2 }}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 8,
                  "&:hover": { backgroundColor: "none", color: "red" },
                }}
              >
                <CloseIcon />
              </IconButton>

              <DialogContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{}}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      mt: 2,
                      fontWeight: "bold",
                      color: "#2b4257",
                      fontSize: isMobile ? "24px" : "30px",
                      fontFamily: "Arial",
                    }}
                  >
                    Add Related Pan Details
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: isMobile ? "100%" : 600,
                      width: isMobile ? "90%" : 400,
                      margin: "auto",
                      padding: isMobile ? 1 : 2,
                      border: "1px solid #ccc",
                      borderRadius: 4,
                    }}
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}

                    >
                      {({ errors, touched, setFieldValue }) => (
                        <Form className="formik-form">
                          <div className="form-group-relatedform">
                            <label htmlFor="panno">PAN NUMBER </label>
                            <Field
                              placeHolder="Enter Relation PAN Num"
                              className="form-input"
                              name="panno"
                              type="text"
                              maxlength="10"
                              onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.panno && touched.panno ? (
                              <div className="error-message">
                                {errors.panno}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group-relatedform">
                            <label htmlFor="confirmpanno"> Confirm PAN NUMBER </label>
                            <Field
                              placeHolder="Confirm Relation PAN Num"
                              className="form-input"
                              name="confirmpanno"
                              type="text"
                              maxlength="10"
                              onChange={(event) => handleChange(event, setFieldValue)}
                            />
                            {errors.confirmpanno && touched.confirmpanno ? (
                              <div className="error-message">
                                {errors.confirmpanno}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group-relatedform">
                            <label htmlFor="name">Name</label>
                            <Field
                              placeHolder="Enter Relation Name"
                              name="name"
                              type="text"
                              className="form-input"
                            />
                            {errors.name && touched.name ? (
                              <div className="error-message">{errors.name}</div>
                            ) : null}
                          </div>

                          <div className="form-group-relatedform">
                            <label htmlFor="relation">Relation</label>
                            <Field
                              name="relation"
                              as="select"
                              className="form-input"
                            >
                              <option value="">Select The RelationShip</option>
                              {familyRelations.map((relation) => (
                                <option
                                  key={relation.value}
                                  value={relation.value}
                                >
                                  {relation.label}
                                </option>
                              ))}
                            </Field>
                            {errors.relation && touched.relation ? (
                              <div className="error-message">
                                {errors.relation}
                              </div>
                            ) : null}
                          </div>

                          <Button sx={{ marginTop: "5px", background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)' }} type="submit">Submit</Button>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default Index;


