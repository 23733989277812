import sliceCreator from '../index.js';
import {EMPLOYEE_DASHBOARD} from "../../../constants/index.js"

const _employeeDashboardAction = sliceCreator('EMPLOYEE_DASHBOARD', EMPLOYEE_DASHBOARD, 'get');

const { asyncAction: employeeDashboardAction, reducer, clearData: clearemployeeDashboardAction } = _employeeDashboardAction;

export default reducer;

export {
    employeeDashboardAction,
    clearemployeeDashboardAction
}
