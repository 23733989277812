import React, { useEffect } from 'react';
import SideBar from "../../../components/sidebar";
import { Box, Paper, Typography, Grid, TextField, InputAdornment, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { editEmployeeRatingAction, cleareditEmployeeRatingAction, employeeRatingAction, clearemployeeRatingAction, globalConfigAction } from "../../../store/actions"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import VerifiedIcon from '@mui/icons-material/Verified';
import Checkbox from '@mui/material/Checkbox';
import { employeeEditSchema } from "./EmployeeEditSchema";
import { useFormik } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress } from '@material-ui/core';

function EmployeeEdit() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { successData, error, errorInfo } = useSelector((state) => state.getEmployeeRatingData) || {};
    const { successData: editDAta, loading:editLoading } = useSelector((state) => state.getEmployeeRating) || {};
    const { successData: globalSuccessData } = useSelector((state) => state.globalConfigData) || {};

    const employeeExperienceId = state?.Id;
    const employeeId = state?.employeeDetails?.id;
    const userDetail = JSON.parse(localStorage.getItem("UserDetail") || "{}");
    const businessId = userDetail.business.id;

    const formik = useFormik({
        initialValues: {
            startDate: '',
            endDate: '',
            designation: '',
            rating: '',
            remarks: '',
            isVerified: true,
            isCurrentExperience: false
        },
        validationSchema: employeeEditSchema,
        onSubmit: (values) => {
            const formatDateForPayload = (dateStr) => {              
                if (!dateStr) return null;
                const [month, year] = dateStr.split('-');
                const monthNum = new Date(Date.parse(month + " 1, 2000")).getMonth() + 1;
                return `${year}-${monthNum.toString().padStart(2, '0')}-01`;
            };

            const payload = {
                user: editDAta ? employeeId : employeeExperienceId,
                organization: businessId,
                start_date: formatDateForPayload(values.startDate),
                end_date: formatDateForPayload(values.endDate),
                designation: values.designation,
                rating: values.rating,
                review: values.remarks,
                is_verified: values.isVerified,
                is_current: values.isCurrentExperience
            };

            if (editDAta) {
                dispatch(editEmployeeRatingAction({
                    endPoint: `employee-experience/${employeeExperienceId}/`,
                    ...payload
                }));
                toast.success("Rating Updated Successfully");
                navigate('/employeeDashboard');
                dispatch(cleareditEmployeeRatingAction());
            } else {
                dispatch(employeeRatingAction(payload));
            }
        }
    });

    const StarRating = ({ rating, setRating, disabled }) => {
        const handleStarClick = (clickedStar) => {
            if (disabled) return;
            formik.setFieldValue('rating', clickedStar === rating ? clickedStar - 1 : clickedStar);
        };

        return (
            <Box sx={{ display: 'flex', gap: 1 }}>
                {[1, 2, 3, 4, 5].map((star) => (
                    <Typography
                        key={star}
                        onClick={() => handleStarClick(star)}
                        sx={{
                            cursor: disabled ? 'default' : 'pointer',
                            color: star <= rating ? '#FFD700' : '#D3D3D3',
                            fontSize: '2rem',
                            opacity: disabled ? 0.7 : 1,
                            '&:hover': {
                                color: !disabled && '#FFD700'
                            }
                        }}
                    >
                        ★
                    </Typography>
                ))}
            </Box>
        );
    };

    const formatDateToMonthYear = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric'
        }).replace(' ', '-');
    };

    const parseMonthYear = (monthYearString) => {
        if (!monthYearString) return '';
        const [month, year] = monthYearString.split('-');
        const monthNum = new Date(Date.parse(month + " 1, 2000")).getMonth() + 1;
        return `${year}-${monthNum.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        if (successData) {
            toast.success("Rating Added Successfully");
            navigate('/employeeDashboard');
            dispatch(clearemployeeRatingAction());
        }
    }, [successData, navigate, dispatch]);

    useEffect(() => {
        if (editDAta) {
            formik.setValues({
                startDate: formatDateToMonthYear(editDAta.start_date),
                endDate: formatDateToMonthYear(editDAta.end_date),
                designation: editDAta.designation,
                remarks: editDAta.review,
                rating: editDAta.rating,
                isVerified: editDAta.is_verified || false,
                isCurrentExperience: editDAta.is_current || false
            });
        }
    }, [editDAta]);

    useEffect(() => {
        if (error) {
            toast.error(errorInfo);
        }
    }, [error, errorInfo]);

    useEffect(() => {
        dispatch(globalConfigAction());
    }, [dispatch]);

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <SideBar />
            <Box sx={{ position: 'absolute', top: 90, right: 30 }}>
                <Button
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                    sx={{
                        backgroundColor: '#82B2C4',
                        '&:hover': {
                            backgroundColor: '#6B99A9'
                        }
                    }}
                >
                    Back
                </Button>
            </Box>
            {editLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <CircularProgress sx={{ color: '#82B2C4' }} />
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: { xs: '15%', sm: '10%', md: '5%' } }}>
                    <Paper sx={{ width: { xs: '95%', sm: '80%', md: '60%' }, p: { xs: '20px', sm: '30px', md: '40px' }, boxShadow: '0px 0px 10.1px 0px rgba(0, 0, 0, 0.25)' }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Typography variant='h4' sx={{
                                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                                fontWeight: 'bold',
                                textAlign: 'center',
                                fontFamily: 'Inter',
                                background: 'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                mb: 4,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                Rate Employee
                                <Box onClick={() => formik.setFieldValue('isVerified', !formik.values.isVerified)} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    {formik.values.isVerified && (
                                        <VerifiedIcon sx={{
                                            color: '#4CAF50',
                                            backgroundColor: '#E8F5E9',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            fontSize: '2rem'
                                        }} />
                                    )}
                                </Box>
                            </Typography>

                            <Grid container spacing={3} mt={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                        Working Period
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                type="month"
                                                label="Start Date"
                                                name="startDate"
                                                value={parseMonthYear(formik.values.startDate)}
                                                onChange={(e) => {
                                                    const date = new Date(e.target.value);
                                                    const formattedDate = date.toLocaleDateString('en-US', {
                                                        month: 'short',
                                                        year: 'numeric'
                                                    }).replace(' ', '-');
                                                    formik.setFieldValue('startDate', formattedDate);
                                                }}
                                                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                                helperText={formik.touched.startDate && formik.errors.startDate}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    sx: {
                                                        fontSize: '16px',
                                                        border: '1px solid',
                                                        borderRadius: '5px',
                                                        borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                    },
                                                }}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    name="isCurrentExperience"
                                                    checked={formik.values.isCurrentExperience}
                                                    onChange={formik.handleChange}
                                                    sx={{
                                                        color: '#47627B',
                                                        '&.Mui-checked': {
                                                            color: '#4A87BE',
                                                        },
                                                    }}
                                                />
                                                <Typography sx={{ color: '#47627B' }}>
                                                    Is This current experience
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                type="month"
                                                label="End Date"
                                                name="endDate"
                                                value={parseMonthYear(formik.values.endDate)}
                                                onChange={(e) => {
                                                    const date = new Date(e.target.value);
                                                    const formattedDate = date.toLocaleDateString('en-US', {
                                                        month: 'short',
                                                        year: 'numeric'
                                                    }).replace(' ', '-');
                                                    formik.setFieldValue('endDate', formattedDate);
                                                }}
                                                disabled={formik.values.isCurrentExperience}
                                                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                                helperText={formik.touched.endDate && formik.errors.endDate}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    sx: {
                                                        fontSize: '16px',
                                                        border: '1px solid',
                                                        borderRadius: '5px',
                                                        borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                    },
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                        Designation
                                    </Typography>
                                    <TextField
                                        select
                                        SelectProps={{ native: true }}
                                        name="designation"
                                        value={formik.values.designation}
                                        onChange={formik.handleChange}
                                        error={formik.touched.designation && Boolean(formik.errors.designation)}
                                        helperText={formik.touched.designation && formik.errors.designation}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Box sx={{ width: '1px', height: '100%', backgroundColor: '#9CA3AF' }} />
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                fontSize: '16px',
                                                border: '1px solid',
                                                borderRadius: '5px',
                                                borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                            },
                                        }}
                                        fullWidth
                                    >
                                        <option value="" disabled>Select Designation</option>
                                        {globalSuccessData?.employee_designation?.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                        Rating
                                    </Typography>
                                    <Box>
                                        <StarRating
                                            rating={Number(formik.values.rating)}
                                            setRating={(value) => formik.setFieldValue('rating', value)}
                                        />
                                        {formik.touched.rating && formik.errors.rating && (
                                            <Typography color="error" variant="caption">
                                                {formik.errors.rating}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>

                                <Grid container spacing={3} mt={3}>

                                <Grid item xs={12} md={10}>
                                        <Typography sx={{ mb: 2, background: 'linear-gradient(269.25deg, #2B4257 20.4%, #5D8FBD 99.43%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontSize: '1rem', fontWeight: 'bold' }}>
                                            Remarks
                                        </Typography>
                                        <TextField
                                            multiline
                                            rows={4}
                                            name="remarks"
                                            value={formik.values.remarks}
                                            onChange={formik.handleChange}
                                            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                                            helperText={formik.touched.remarks && formik.errors.remarks}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Box sx={{ width: '1px', height: '100%', backgroundColor: '#9CA3AF' }} />
                                                    </InputAdornment>
                                                ),
                                                sx: {
                                                    width: '100%',
                                                    fontSize: '16px',
                                                    border: '1px solid',
                                                    borderRadius: '5px',
                                                    borderImage: 'linear-gradient(90deg, #0057ED 0%, #2C5172 100%) 1',
                                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                                    opacity: !formik.values.isVerified ? 0.7 : 1,
                                                },
                                            }}
                                            sx={{
                                                width: '100%',
                                                borderRadius: '5px',
                                                '& .MuiInputBase-root': { paddingRight: '8px' },
                                                '& .MuiInputBase-input': { padding: '4px 6px' },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                                    {!editDAta?.is_verified && (
                                        <Box sx={{
                                            textAlign: 'center',
                                            mb: 2,
                                            mt: 2,
                                            px: 3,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Checkbox
                                                    name="isVerified"
                                                    checked={formik.values.isVerified}
                                                    onChange={formik.handleChange}
                                                    sx={{
                                                        color: '#47627B',
                                                        '&.Mui-checked': {
                                                            color: '#4A87BE',
                                                        },
                                                    }}
                                                />
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        color: '#666',
                                                        paddingTop: '20px',
                                                    }}
                                                >
                                                    By submitting this rating, you confirm that you are verifying this employee's work experience.
                                                </Typography>
                                            </Box>
                                            {formik.touched.isVerified && formik.errors.isVerified && (
                                                <Typography color="error" variant="caption">
                                                    {formik.errors.isVerified}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}

                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                                <Button
                                    type="submit"
                                    sx={{
                                        background: 'linear-gradient(90deg, #47627B 0%, #4A87BE 100%)',
                                        color: '#fff',
                                        px: 4,
                                        py: 1,
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #4A87BE 0%, #47627B 100%)',
                                        }
                                    }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Paper>
                </Box>
            </Box>
            )}
        </Box>
    );
}

export default EmployeeEdit;

