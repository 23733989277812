import React, { useState, useEffect,useRef } from "react";
import "./Searchprofile.scss";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ShareOutlined, PrintOutlined, DownloadOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import MoreDetails from "../moredetails/ratemycustomer";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyPartnerAction,
  globalConfigAction,
  registrationAction,
  clearRegistrationData,
  getProfileAction
} from "../../store/actions";
import { Box, Grid, Typography, Stack, Checkbox, Tooltip } from "@mui/material";
import Sidebar from "../../components/sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getLableAndValuesFromData, dispatchAll, getOverallDetailsOfUser } from "../../utils";
import Select from "react-select";
import { toast } from "react-toastify";
import PlanUpgradeModel from "../../components/CustomModals/PlanUpgradeModel"
import ReCAPTCHA from 'react-google-recaptcha';
import {SITE_KEY} from "../../constants/index"
import Ratingcard from "../../components/ratingcard";
import { create } from "lodash";

const Searchprofile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [addCustomer, setAddCustomer] = useState(false);
  const [industryTypes, setIndustryTypes] = React.useState([]);
  const [partnerTypes, setPartnerTypes] = React.useState([]);
  const recaptchaRef = useRef(null);

  const [partnerData, setPartnerData] = useState(location.state);
  const [partnerRating, setPartnerRating] = useState({});
  const [industryValue, setIndustryValue] = React.useState([]);
   const [showPlanModal, setShowPlanModal] = useState(false);
   const [searchType, setSearchType] = useState('');
  
  const { successData: myPartnersData } =
    useSelector((state) => state.getMyPartnerData) || {};

  const { successData: globalSuccessData } =
    useSelector((state) => state.globalConfigData) || {};

    const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaChecked,setCaptchaChecked] = useState(false)
  const [partnersRatings, setPartnersRatings] = React.useState([]);
  const [overallDetails, setOverallDetails] = React.useState({
    overAllRatings: {
      1: 0.0,
      2: 0.0,
      3: 0.0,
      4: 0.0,
      5: 0.0,
      6: 0.0,
      7: 0.0,
      8: 0.0,
      9: 0.0,
      10: 0.0,
    },
    totalAmountBalance: 0,
    totalCustomers: 0,
    totalPartners: 0,
    avgRating: 0,
  });

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaChecked(false)
    
  };

  useEffect(()=>{
   console.log(captchaValue)
  },[captchaValue])
  
  

  const {
    successData: registrationSuccessData,
    error: registrationError,
    errorInfo: registrationErrorInfo,
  } = useSelector((state) => state.registrationData) || {};

  const dispatch = useDispatch();

  const normalizeSearchResponse = (res) => {
    if (!res) return null;

    // For mobile search response
    if (res.user) {
      console.log("Mobile search response:", res.user);
      console.log("Mobile relationships:", res.partners);
      const result = {
        ...res.user,
        partners: res.partners[0] || []
      };
      console.log("Normalized mobile result:", result);
      return result;
    }
    
    // For PAN/GST search respons
    const result = {
      ...res,
      partners: res.linked_relationships || []
    };
    return result;
  };
  
  useEffect(() => {
    if (location.state) {
      const normalizedData = normalizeSearchResponse(location.state);
      setPartnerData(normalizedData);
      setSearchType(location.state.user ? 'mobile' : 'pangst');  
      
    }
  }, [location.state]);
  

const { successData:profileData } = useSelector(state => state.profileData);


    useEffect(() => {
        // Fetch profile data when component mounts
        dispatch(getProfileAction());
    }, [dispatch]);

  const handleLinktoCustomer = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setCaptchaChecked(false)
    }
    if (partnerData?.is_user_linked) {
      const business_partner_assoc_id = partnerData?.id;
      const partnerRatingData = myPartnersData.filter(
        (item) => item.business_partner_assoc.id === business_partner_assoc_id
      );
      setPartnerRating(partnerRatingData[0]);
      setShowModal(true);
    }
    if (profileData.premium_type === 1) {
      if (partnerData?.business?.state === profileData.business?.state) {
        navigate("/moredetails", {
         state: { id: partnerData["id"], is_business_exists: true },
         replace: false, 
        });
      } else {
        setShowPlanModal(true);
      }
    } else if (profileData.premium_type === 2 || profileData.premium_type === 3 || profileData.premium_type === 4) {
      navigate("/moredetails", {
       state: { id: partnerData["id"], is_business_exists: true },
        replace: false,
      });
    } else {
      setShowPlanModal(true);
    }
  };

  
  const handleViewDetails = () => {
    navigate("/partnerdetails", {
      state: { selectedAssocPartner: partnerData },
      replace: false,
    });
  };


  const handleEditRating = () => {
    navigate("/editviewrating", {
      state: {
        partnerFullData: partnerData,
        isEdit: true,
      },
    });
  };
  const onUpdateRatingSuccess = () => {
    dispatch(getMyPartnerAction());
    setShowModal(false);
  };
  const fullName = partnerData?.is_from_gst
    ? partnerData?.fullname
    : partnerData?.first_name;

  const initialValues = {
    industry: {},
    partner: {},
    phoneNumber: "",
    email: "",
    partnerConsent: false,
    skipEmail: false,
  };

  const gstLinkPartnerSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number should be exactly 10 digits")
      .matches(/^\d*$/, "Phone number should not contain alphabetic characters")
      .required("Please enter a valid 10-digit phone number"),
    industry: Yup.object().shape({
      value: Yup.string().required("Industry is required*"),
      label: Yup.string(),
    }),
    partner: Yup.object().shape({
      value: Yup.string().required("Partner Type is required*"),
      label: Yup.string(),
    }),
    partnerConsent: Yup.bool().oneOf(
      [true],
      "Please agree before submitting your rating*"
    ),
    email: Yup.string().when("skipEmail", (skipEmail, schema) => {
      return skipEmail[0] ? schema : schema.required("Email is Required").matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,"Enter a Valid Email");

    })


    
  
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: gstLinkPartnerSchema,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      // Handle form submission here
      if (!captchaValue) {
        setCaptchaChecked(true)
        return;
      }
      
      const userData = {
        user: {
          email: values.skipEmail ? "NA" : values.email,
          user_type: values.partner.value,
          first_name: partnerData?.fullname,
          pan_number: partnerData.gstin.substring(
            2,
            partnerData?.gstin.length - 3
          ), // get pan from gstin
          phone_number: values.phoneNumber,
          captcha: captchaValue,
          created_by: profileData.id,
        },
        business: {
          industry: values.industry.value,
          // company: values.company.value,
          name: partnerData.business_name,
          pan_number: partnerData.gstin.substring(
            2,
            partnerData?.gstin.length - 3
          ), // get pan from gstin
          city: partnerData.city,
          incorporation_date: partnerData.incorporation_date,
          state: partnerData.state,
          district: partnerData.district,
        },
      };
        if (values.skipEmail=== true) {
          console.log(values.skipEmail)
          delete userData.user.email;
        }
      console.log(userData);
      dispatch(registrationAction(userData));
    },
  });


  // Add the "None" option to the industry, partner, and company type fields
  const industryOptions = [...industryTypes];
  const partnerOptions = [...partnerTypes];

  const handleCloseModal = () => {
    navigate("/dashboard")
    setShowPlanModal(false);

  };

  React.useEffect(() => {
    return () => dispatchAll([clearRegistrationData], dispatch);
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  React.useEffect(() => {
    if (globalSuccessData) {
      setIndustryTypes(
        getLableAndValuesFromData(globalSuccessData.industry_type)
      );
      setPartnerTypes(
        getLableAndValuesFromData(globalSuccessData.partner_type)
      );
      if (partnerData?.is_user_exists) {
        const industryType = globalSuccessData.industry_type.find(
          (industry) => industry.id === partnerData?.business.industry
        );
        setIndustryValue(industryType?.name);
      }
    }
  }, [globalSuccessData, partnerData]);

  React.useEffect(() => {
  if (registrationSuccessData?.success) {
    toast.success("Customer Details added successfully");
    if (profileData.premium_type === 1) {
      if (partnerData.state === profileData.business.state) {
        navigate("/moredetails", {
          state: {
            id: registrationSuccessData["data"]["user"]["id"],
            is_business_exists: false,
          },
          replace: false,
        });
      } else {
        setShowPlanModal(true);
      }
    } else if (profileData.premium_type === 2 || profileData.premium_type === 3 || profileData.premium_type === 4) {
      navigate("/moredetails", {
        state: {
          id: registrationSuccessData["data"]["user"]["id"],
          is_business_exists: false,
        },
        replace: false,
      });
    } else {
      setShowPlanModal(true);
    }
  }
}, [registrationSuccessData, navigate, partnerData, profileData]);
  React.useEffect(() => {
    if (registrationError) {
      toast.error(registrationErrorInfo);
      dispatch(clearRegistrationData());
    }
  }, [registrationError, registrationErrorInfo, dispatch]);

  React.useEffect(() => {
    if (Array.isArray(partnerData.partners) && partnerData.partners.length > 0) {
      setPartnersRatings([...partnerData.partners]);
      setOverallDetails({ ...getOverallDetailsOfUser(partnerData.partners) });
    } else if (partnerData.partners) {
      setPartnersRatings([partnerData.partners]); // Wrap in an array
      setOverallDetails({ ...getOverallDetailsOfUser([partnerData.partners]) });
    }
}, [partnerData]);

const handleDownloadConsent = () => {
  window.open('/documents/consentForm.docx', '_blank');
};

const handlePrintConsent = () => {
  const printWindow = window.open('/documents/consentForm.docx', '_blank');
  printWindow.onload = () => {
    printWindow.print();
  };
};

const handleShareConsent = async () => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: 'Consent Form',
        text: 'Please check this consent form',
        url: window.location.origin + '/documents/consentForm.docx'
      });
    } catch (error) {
      console.log('Error sharing:', error);
    }
  } else {
    // Fallback - copy link to clipboard
    navigator.clipboard.writeText(window.location.origin + '/documents/consentForm.docx');
    toast.success('Link copied to clipboard!');
  }
};

  return (
    <Box sx={{ display: "flex", minHeight: "90vh" }}>
      <Sidebar />
       <PlanUpgradeModel
        show={showPlanModal}
        handleClose={handleCloseModal}
      />
      {(searchType === 'mobile' && !partnerData.is_user_linked)  
    ? (<Box component="main" sx={{ flexGrow: 1, p: 0, display: "flex", alignItems: "center", justifyContent: "start" }} mt={8}>
      <div className="overlay" >
        <div className="rectangle" style={{ width: "90%", maxWidth: "350px", height: "50%", }}>
          <h2 style={{ fontSize: "14px", textAlign: "center", marginBottom: "5px", }}>
            Overall Rating for {`${partnerData.business.name}`}
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "5px", 
            }}
          >
            <Avatar
              sx={{
                bgcolor: "deepOrange[500]",
                width: "15%", 
                height: "40px",
                fontSize: "0.8rem",
              }}
            >
              {overallDetails.avgRating}/10
            </Avatar>
          </div>

          <Typography
            style={{
              fontWeight: "bold",
              marginTop: "3px", 
              fontSize: "16px",
              textAlign: "center",
            }}
            className="riv"
          >
            Total: {partnersRatings.length} Ratings
          </Typography>
          <div
            className="partnerdetailsrating"
            style={{ marginTop: "5px", padding: "2px" }}
          >
            <Ratingcard style={{ padding: "5px", margin: "0", maxHeight: '20%' }}
              overallDetails={overallDetails}
              partnerName={`for ${partnerData.business.name}`}
              partnersRatings={partnersRatings}
            />
          </div>
        </div>
      </div>
      <Box sx={{ width: '70%' }}>
        <Typography sx={{ fontSize: '1.5rem' ,color:'#2b4257'}}>You need link and rate using GST or PAN of the user to get complete insights and comments</Typography>
      </Box>
      </Box>): ( 
        <Box component="main" sx={{ flexGrow: 1, p: 0 }} mt={8}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          mt={0}
          ml={2}
          style={{ textAlign: "center" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h4"><b>Search Results</b></Typography>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={0}
            ml={2}
            style={{ textAlign: "center"}}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={0}
              ml={2}
              style={{ textAlign: "center"}}
            >
              {/* <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
                marginBottom="5px"
              >
                <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    backgroundColor: "rebeccapurple",
                  }}
                >
                  {fullName
                    ?.split(" ")
                    .filter((word) => word.length > 0)
                    .map((word) => word[0])
                    .join("")
                    .toUpperCase()}
                </Avatar>
              </Stack> */}
              <Grid container spacing={3} pr={4}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="name"
                      className="form-label"
                      style={{ textAlign: "start" }}
                    >
                      Name
                    </label>
                  </div>

                  <input
                    id="name"
                    type="text"
                    readOnly
                    value={
                      partnerData.is_from_gst
                        ? partnerData.fullname
                        : partnerData.first_name
                    }
                    className="form-control input-width"
                    style={{backgroundColor:"#fff",border:"1px solid #ccc"}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="name"
                      style={{ textAlign: "start" }}
                      className="form-label"
                    >
                      Business Name
                    </label>
                  </div>
                  <input
                    type="text"
                    readOnly
                    value={
                      partnerData.is_from_gst
                        ? partnerData.business_name
                        : partnerData?.business?.name
                    }
                    className=" form-control input-width"
                    style={{backgroundColor:"#fff",border:"1px solid #ccc"}}
                  />
                </Grid>
                {partnerData.is_user_exists && (
                    <Grid item xs={12} sm={6} md={4} lg={4}  style={{ zIndex: 0 }}>
                    <div style={{ display: "flex" }}>
                      <label
                        htmlFor="name"
                        style={{ textAlign: "start" }}
                        className="form-label"
                      >
                        Industry
                      </label>
                    </div>
                    <input
                      type="text"
                      readOnly
                      // value={partnerData.is_from_gst? partnerData.industry?.[0] : partnerData?.business?.industry?.name}
                      value={industryValue}
                      className="form-control input-width"
                      style={{backgroundColor:"#fff",border:"1px solid #ccc"}}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="name"
                      style={{ textAlign: "start" }}
                      className="form-label"
                    >
                      {" "}
                      City
                    </label>
                  </div>
                  <input
                    type="text"
                    readOnly
                    value={
                      partnerData.is_from_gst
                        ? partnerData.city
                        : partnerData?.business?.city
                    }
                    className=" form-control input-width"
                    style={{backgroundColor:"#fff",border:"1px solid #ccc"}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="name"
                      style={{ textAlign: "start" }}
                      className="form-label"
                    >
                      District
                    </label>
                  </div>
                  <input
                    type="text"
                    readOnly
                    value={
                      partnerData.is_from_gst
                        ? partnerData.district
                        : partnerData?.business?.district
                    }
                    className="form-control input-width"
                    style={{backgroundColor:"#fff",border:"1px solid #ccc"}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="name"
                      style={{ textAlign: "start" }}
                      className="form-label"
                    >
                      State
                    </label>
                  </div>
                  <input
                    type="text"
                    readOnly
                    value={
                      partnerData.is_from_gst
                        ? partnerData.state
                        : partnerData?.business?.state
                    }
                    className="form-control input-width"
                    style={{backgroundColor:"#fff",border:"1px solid #ccc"}}
                  />
                </Grid>
              </Grid>
              {/* search with non-existing gst flow start */}
              {/* Button to add more details */}
              {!addCustomer && !partnerData?.is_user_exists && (
                <Button
                  variant="primary"
                  className="searchprofilebutton"
                  style={{ marginTop: "20px", width: "auto" }}
                  onClick={() => setAddCustomer(!addCustomer)}
                >
                  Add More Details to Link as a Partner
                </Button>
              )}

              {/* Form to add more details */}
              {addCustomer && (
                <form onSubmit={handleSubmit} className="form-style">
                  <Grid container spacing={3} mt={1} pr={4} textAlign={"start"}>
                    <Grid  item xs={12} sm={6} md={4} lg={4} textAlign={"start"}>
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        style={{ textAlign: "start"}}
                      >
                        Industry
                      </label>
                      <Select
                        // required
                        defaultValue={values.industry}
                        value={values.industry}
                        name="industry"
                        id="industry"
                        onChange={(selectedIndustry) =>
                          setFieldValue("industry", selectedIndustry)
                        }
                        options={industryOptions} // Use the updated options array
                      />
                      {errors.industry && touched.industry ? (
                        <p className="form-error error-message remove-margin">
                          {errors.industry.value}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label"
                      >
                        Business Type
                      </label>
                      <Select
                        defaultValue={values.partner}
                        name="partner"
                        id="partner"
                        value={values.partner}
                        onChange={(selectedPartner) =>
                          setFieldValue("partner", selectedPartner)
                        }
                        options={partnerOptions} // Use the updated options array
                      />
                      {errors.partner && touched.partner ? (
                        <p className="form-error error-message remove-margin">
                          {errors.partner.value}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={"90%"}
                      >
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          {" "}
                          Email ID
                        </label>
                        <div>
                          <label htmlFor="skipEmail">Skip Email?</label>
                          <input
                            style={{ marginLeft:"10px"}}
                            type="checkbox"
                            id="skipEmail"
                            name="skipEmail"
                            value={values.skipEmail}
                            onChange={handleChange}
                            
                          />
                        </div>
                      </Grid>
                      <input
                        type="email"
                        name="email"
                        className="form-control input-width"
                        autoComplete="off"
                        value={values.skipEmail ? "NA" : values.email}
                        id="email"
                        placeholder="Email ID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={values.skipEmail}
                      />
                      {errors.email && touched.email ? (
                        <p className="form-error error-message remove-margin">
                          {errors.email}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <label
                        htmlFor="exampleFormControlInput3"
                        className="form-label"
                      >
                        {" "}
                        Mobile Number
                      </label>
                      <br />
                      <input
                        type="phoneNumber"
                        name="phoneNumber"
                        placeholder="Mobile Number"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        id="phoneNumber"
                        onInput={(e) => {
                          // Use a regular expression to remove non-numeric characters
                          e.target.value = e.target.value.replace(/\D/g, "");
                        }}
                      />
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <p className="form-error error-message remove-margin">
                          {errors.phoneNumber}
                        </p>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={SITE_KEY}
                        onChange={handleRecaptchaChange}
                      />
                      {captchaChecked && <p style={{color:'red'}}>please complete the captcha*</p>}
                    </Grid>
                  </Grid>
                    <Stack
                      pt={2}
                      pl={3}
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 2, sm: 1 }}
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                    >
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          name="partnerConsent"
                          id="partnerConsent"
                          onChange={handleChange}
                          checked={values.partnerConsent}
                        />
                        <label htmlFor="partnerConsent" className="form-label remove-margin-bottom" >
                          Please agree that you have consent to link the partner/submit rating
                        </label>
                      </Box>

                      <Box
                        component="span"
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        gap="10px"
                        sx={{ mt: { xs: 1, sm: 0 } }}
                      >
                        <IconButton onClick={handleDownloadConsent} size="small" title="Download">
                          <DownloadOutlined sx={{ height: '18px', width: '18px' }} />
                        </IconButton>
                        <IconButton onClick={handlePrintConsent} size="small" title="Print">
                          <PrintOutlined sx={{ height: '18px', width: '18px' }} />
                        </IconButton>
                        <IconButton onClick={handleShareConsent} size="small" title="Share">
                          <ShareOutlined sx={{ height: '18px', width: '18px' }} />
                        </IconButton>
                        <Typography
                          component="span"
                          fontSize={{ xs: '14px', sm: '16px' }}
                          color="#007aff"
                          onClick={handleDownloadConsent}
                          sx={{ cursor: 'pointer' }}
                        >
                          Consent Form
                        </Typography>
                      </Box>
                    </Stack>
                  <Stack alignItems={"start"}>
                    {errors.partnerConsent && touched.partnerConsent ? (
                      <p className="form-error error-message remove-margin checkbox-error-message">
                        {errors.partnerConsent}
                      </p>
                    ) : null}
                  </Stack>

                  <div className="text-center">
                    <Button
                      type="submit"
                      variant="primary"
                      className="searchprofilebutton"
                      style={{
                        marginTop: "-15px",
                        width: "200px",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Link your Partner
                    </Button>
                  </div>
                </form>
              )}
              {/* search with non-existing gst flow end  */}
            </Grid>
          </Grid>
        </Grid>

        {partnerData?.is_user_exists && !partnerData?.is_user_linked  && (
            <Stack 
            pt={2} 
            pl={3} 
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, sm: 1 }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                name="partnerConsent"
                id="partnerConsent"
                onChange={handleChange}
                checked={values.partnerConsent}
              />
              <label htmlFor="partnerConsent" className="form-label remove-margin-bottom" style={{marginRight: '8px'}}>
                Please agree that you have consent to link the partner/submit rating
              </label>
            </Box>
          
            <Box 
              component="span" 
              display="flex"
              flexWrap="wrap"
              alignItems="center" 
              gap="10px"
              sx={{ mt: { xs: 1, sm: 0 } }}
            >
              <IconButton onClick={handleDownloadConsent} size="small" title="Download">
                <DownloadOutlined sx={{ height: '18px', width: '18px' }} />
              </IconButton>
              <IconButton onClick={handlePrintConsent} size="small" title="Print">
                <PrintOutlined sx={{ height: '18px', width: '18px' }} />
              </IconButton>
              <IconButton onClick={handleShareConsent} size="small" title="Share">
                <ShareOutlined sx={{ height: '18px', width: '18px' }} />
              </IconButton>
              <Typography 
                component="span" 
                fontSize={{ xs: '14px', sm: '16px' }}
                color="#007aff" 
                onClick={handleDownloadConsent} 
                sx={{ cursor: 'pointer' }}
              >
                Consent Form
              </Typography>
            </Box>
          </Stack>

        )}

        <Stack direction={"row"} justifyContent="center">
          {partnerData?.is_user_linked && (
            <Button
              variant="secondary"
              onClick={handleViewDetails}
              className="card-link"
              style={{ marginTop: "30px", width: "200px" }}
            >
              View Details
            </Button>
          )}

          {partnerData?.is_user_exists && (
            <Tooltip
              title={
                !partnerData?.is_user_linked && !values.partnerConsent
                  ? "Please agree that you have consent to submit rating"
                  : ""
              }
            >
              <Button
                variant="primary"
                className="searchprofilebutton"
                style={{ marginTop: "30px", width: "200px",background:'linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)' }}
                // onClick={handleLinktoCustomer}
                onClick={
                  partnerData?.is_user_linked
                    ? handleEditRating
                    : handleLinktoCustomer
                }
                disabled={!partnerData?.is_user_linked && !values.partnerConsent}
              >
                {partnerData?.is_user_linked
                  ? "Edit your rating"
                  : "Link your Partner"}
              </Button>
            </Tooltip>
          )}
        </Stack>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <MoreDetails
              isEdit={true}
              partnerData={partnerRating}
              onUpdateRatingSuccess={onUpdateRatingSuccess}
            />

            <FontAwesomeIcon
              className="close"
              icon={faTimes}
              onClick={() => setShowModal(false)}
            />
          </Modal.Body>
        </Modal>
      </Box>)}
    </Box>
  );
};

export default Searchprofile;




