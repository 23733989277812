import sliceCreator from '../index.js';
import {EMPLOYEE_RATING} from "../../../constants/index.js"

const _editEmployeeRatingAction = sliceCreator('EMPLOYEE_RATING', EMPLOYEE_RATING, 'put');

const { asyncAction: editEmployeeRatingAction, reducer, clearData: cleareditEmployeeRatingAction } = _editEmployeeRatingAction;

export default reducer;

export {
    editEmployeeRatingAction,
    cleareditEmployeeRatingAction
}
