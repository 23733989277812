import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import UpdateIcon from '@mui/icons-material/Update';
import PublicIcon from '@mui/icons-material/Public';
import { useDispatch, useSelector } from 'react-redux';
import { globalConfigAction } from "../../store/actions";
import './home.css';

const OurNetwork = () => {
  const dispatch = useDispatch();
  const [logos, setLogos] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { successData: globalConfigData } = useSelector(state => state.globalConfigData) || {};



  useEffect(() => {
    dispatch(globalConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (globalConfigData) {
      setLogos(globalConfigData.logos.seed.slice(1));
      console.log(globalConfigData.logos);
    }
  }, [globalConfigData]);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };


  return (
    <Grid container direction="column" sx={{ width: '100%' }}>
     <Grid
        container
        spacing={{ xs: 2, sm: 4 }}
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyContent: 'space-around',
          alignItems: { xs: 'flex-start', sm: 'center' },
          padding: { xs: '10% 2% 2% 2%', sm: '20px' },
          background: { xs: 'linear-gradient(177.95deg, #FFFFFF 9.32%, rgba(185, 195, 252, 0.41) 77.36%)', sm: 'none' }
        }}
      >
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <StarBorderIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Spot Threats Before They Happen</span>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <PersonSearchIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Faster Debt Recovery</span>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <UpdateIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Real-time Updates</span>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#31303b',
          fontSize: { xs: '1rem', sm: '1rem' },
          fontWeight: 500,
          fontFamily: 'Georgia, serif',
          gap: '10px'
        }}>
          <PublicIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} />
          <span>Trustworthy Network</span>
        </Grid>
      </Grid>

      <Grid item sx={{ textAlign: 'center', padding: '20px' }}>
        <h2
          style={{
            fontFamily: 'Ruwudu',
            fontSize: { xs: '1.5rem', sm: '2rem' },
            color: '#000',
            marginBottom: '20px'
          }}
        >
          Our Network
        </h2>
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '120px',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '30px',
            backgroundColor: '#fff',
            maxWidth: { sm: '95vw', xs: '89vw' },
            padding: '0 20px',
            boxSizing: 'border-box'
          }}
        >
          <motion.div
            style={{
              display: 'inline-flex',
              gap: '60px',
              whiteSpace: 'nowrap'
            }}
            animate={{
              x: ["0%", "-100%"], 
            }}                                    
            transition={{
              duration: 30,
              repeat: Infinity,
              ease: 'linear'
            }}
          >
            {logos?.map((logoUrl, index) => (
              <div
                key={index}
                style={{ display: 'inline-block' }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <motion.img
                  src={logoUrl}
                  alt={`Logo ${index}`}
                  style={{
                    maxWidth: '160px',
                    maxHeight: '80px',
                    height: 'auto',
                    transition: 'transform 0.3s ease'
                  }}
                  animate={{
                    scale: hoveredIndex === index ? 1.1 : 1,
                  }}
                />
              </div>
            ))}
          </motion.div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurNetwork;
