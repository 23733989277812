import sliceCreator from '../index.js';
import {EMPLOYEE_SEARCH} from "../../../constants/index.js"

const _employeeSearchAction = sliceCreator('EMPLOYEE_SEARCH', EMPLOYEE_SEARCH, 'get');

const { asyncAction: employeeSearchAction, reducer, clearData: clearemployeeSearchAction } = _employeeSearchAction;

export default reducer;

export {
    employeeSearchAction,
    clearemployeeSearchAction
}
