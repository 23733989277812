import sliceCreator from '../index.js';
import {BUSINESS_NAME_SEARCH} from "../../../constants/index.js"

const _businessNameSearchAction = sliceCreator('BUSINESS_NAME_SEARCH', BUSINESS_NAME_SEARCH, 'get');

const { asyncAction:businessNameSearchAction, reducer, clearData: clearbusinessNameSearchAction } = _businessNameSearchAction;

export default reducer;

export {
    businessNameSearchAction,
    clearbusinessNameSearchAction
}
