import sliceCreator from '../index.js';
import {EMPLOYEE_DETAILS} from "../../../constants/index.js"

const _employeeDetailsAction = sliceCreator('EMPLOYEE_DETAILS', EMPLOYEE_DETAILS, 'get');

const { asyncAction: employeeDetailsAction, reducer, clearData: clearemployeeDetailsAction } = _employeeDetailsAction;

export default reducer;

export {
    employeeDetailsAction,
    clearemployeeDetailsAction
}
