import React, { useEffect,useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import indiaTopoJson from "../../../assets/India.topo.json";
import {
  Paper,
  Box,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  // useMediaQuery,
  // useTheme,
  Typography,
} from "@mui/material";

// import {BarChart, Bar,XAxis, YAxis,  Tooltip, Legend,ResponsiveContainer,} from "recharts";

import { HiUserGroup } from "react-icons/hi2";
import { FaUsersSlash, FaCreditCard } from "react-icons/fa";
import { FcRating } from "react-icons/fc";

import { GiDiscussion } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDashboardAction } from "../../../store/actions";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [tooltipContent, setTooltipContent] = useState("");
  const { successData: AdminDashboardData } =
    useSelector((state) => state.getAdminDashboardAction) || {};

  useEffect(() => {
    dispatch(getAdminDashboardAction());
  }, [dispatch]);

  const data = AdminDashboardData?.ratings_by_state || [];
  const ratingsByState = data.reduce((acc, { state, Ratting_count }) => {
    acc[state] = Ratting_count;
    return acc;
  }, {});

  const userData = {
    totalUsers: AdminDashboardData?.total_users || 0o0,
    activeUsers: AdminDashboardData?.total_active_users || 0o0,
    inactiveUsers: AdminDashboardData?.total_inactive_users || 0o0,
    totalNewUsers: AdminDashboardData?.last_30_days_users || 0o0,
    totalPayments: AdminDashboardData?.total_payments || 0o0,
    totalDisputes: AdminDashboardData?.total_disputes || 0o0,
    total_ratings: AdminDashboardData?.total_ratings || 0o0,
    last_30_days_ratings: AdminDashboardData?.last_30_days_ratings || 0o0,
  };

  // const sixMonthsData = [
  //   { name: "January", users: 200 },
  //   { name: "February", users: 350 },
  //   { name: "March", users: 450 },
  //   { name: "April", users: 600 },
  //   { name: "May", users: 800 },
  //   { name: "June", users: 1000 },
  // ];

  // const twelveMonthsData = [
  //   { name: "July", users: 1200 },
  //   { name: "August", users: 1500 },
  //   { name: "September", users: 1800 },
  //   { name: "October", users: 2000 },
  //   { name: "November", users: 2300 },
  //   { name: "December", users: 2500 },
  //   { name: "January", users: 2700 },
  //   { name: "February", users: 3000 },
  //   { name: "March", users: 3200 },
  //   { name: "April", users: 3500 },
  //   { name: "May", users: 3800 },
  //   { name: "June", users: 4000 },
  // ];

  // Responsive design
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // // State for selected option
  // const [selectedDuration, setSelectedDuration] = useState("6");

  // // Determine the data based on the selected duration
  // const chartData = selectedDuration === "6" ? sixMonthsData : twelveMonthsData;

  return (
    <Box
      overFlow={"hidden"}
      boxShadow={3}
      margin={4}
      borderRadius={1}
      padding={2}
      display={"flex"}
      flexDirection={"column"}
      sx={{ backgroundColor: "lightgrey" }}
    >
      <Typography m={2} variant="h4">
        Hello Admin
      </Typography>
      <Box>
        <Box
          width="100%"
          sx={{
            display: "flex",
            flexWrap: "wrap",

            marginTop: "20px",
          }}
        >
          {/* Total Users */}
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#e6eeff",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <HiUserGroup fill="Blue" size={"40"} />
            </Box>
            <Box marginTop={2}>
              <h3 style={{ color: "Blue", fontWeight: "bold" }}>
                {userData.totalUsers}
              </h3>
              <p style={{ margin: "0px" }}>Total Users</p>
            </Box>
          </Paper>
          {/* Active Users */}
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ccffcc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <HiUserGroup fill="green" size={"40"} />
            </Box>
            <Box marginTop={2}>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                {userData.activeUsers}
              </h3>
              <p style={{ margin: "0px" }}>Active Users</p>
            </Box>
          </Paper>
          {/* Inactive Users */}
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffcccc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <FaUsersSlash fill="red" size={"40"} />
            </Box>
            <Box marginTop={2}>
              <h3 style={{ color: "red", fontWeight: "bold" }}>
                {userData.inactiveUsers}
              </h3>
              <p style={{ margin: "0px" }}>Blocked Users</p>
            </Box>
          </Paper>
          {/* Disputes */}
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffebcc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <GiDiscussion size={"40"} />
            </Box>
            <Box marginTop={2}>
              <h3 style={{ ontWeight: "bold" }}>{userData.totalNewUsers}</h3>
              <p style={{ margin: "0px" }}>Total New Users </p>
            </Box>
          </Paper>
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#f5eew2",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <FaCreditCard fill="#ffce1b" size={"40"} />
            </Box>
            <Box marginTop={2} textAlign={"center"}>
              <h3 style={{ color: "#ffce1b", fontWeight: "bold" }}>
                {userData.totalPayments}
              </h3>
              <p> Total Payments</p>
            </Box>
          </Paper>
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffebcc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <GiDiscussion size={"40"} />
            </Box>
            <Box marginTop={2} textAlign={"center"}>
              <h3 style={{ color: "Black", fontWeight: "bold" }}>
                {userData.totalDisputes}
              </h3>
              <p> Pending Disputes</p>
            </Box>
          </Paper>
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#775f8c",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <FcRating size={"40"} />
            </Box>
            <Box marginTop={2} textAlign={"center"}>
              <h3 style={{ color: "#8d3bd4", fontWeight: "bold" }}>
                {userData.total_ratings}
              </h3>
              <p> Total Ratings</p>
            </Box>
          </Paper>
          <Paper
            borderRadius={1}
            sx={{
              width: { xs: "100%", md: "calc(33.33% - 20px)" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffebcc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <FcRating size={"40"} />
            </Box>
            <Box marginTop={2} textAlign={"center"}>
              <h3 style={{ color: "#8d3bd4", fontWeight: "bold" }}>
                {userData.last_30_days_ratings}
              </h3>
              <p> Total New Ratings</p>
            </Box>
          </Paper>
        </Box>
        <div>
          {/* <Box
          margin={1}
          backgroundColor={"white"}
          width="50%"
          borderRadius={4}
          display="flex"
          alignContent={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Box
            width={"100%"}
            padding={5}
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box>
              <h7>
                User Growth (
                {selectedDuration === "6" ? "6 Months" : "12 Months"})
              </h7>
            </Box>
            <Box>
              <FormControl sx={{ marginBottom: "20px" }}>
                <InputLabel id="duration-select-label">Duration</InputLabel>
                <Select
                  labelId="duration-select-label"
                  id="duration-select"
                  value={selectedDuration}
                  onChange={(e) => setSelectedDuration(e.target.value)}
                  label="Duration"
                >
                  <MenuItem value={"6"}>6 Months</MenuItem>
                  <MenuItem value={"12"}>12 Months</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <ResponsiveContainer width="100%" height={isSmallScreen ? 300 : 400}>
            <BarChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="users" fill="#a0db21" />
            </BarChart>
          </ResponsiveContainer>
        </Box> */}
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Typography variant="h6">
          State Wise Ratings
        </Typography>
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 600, // Adjust scale for better size
            center: [80, 22], // Center the map on India (longitude, latitude)
          }}
          style={{
            width: "100%",
            height: "90%",
          }}
        >
          <Geographies geography={indiaTopoJson}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const stateName = geo.properties.st_nm;
                const ratingCount = ratingsByState[stateName] || 0;
        
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={ratingCount > 0 ? "#FF5722" : "#EEE"}
                    stroke="#FFF"
                    onMouseEnter={() => {
                      setTooltipContent(`${stateName}: ${ratingCount}`)
        
                    }}
                    onMouseLeave={() => setTooltipContent("")}
                  style={{
                    default: { fill: "#D6D6DA", outline: "none" },
                    hover: { fill: "#F53", outline: "none" },
                    pressed: { fill: "#E42", outline: "none" },
                  }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        {true && (
        <div
          style={{
            position: "absolute",
            top: "197px",
            left: "550px",
            padding: "4px",
            border: "1px solid red",
            borderRadius: "4px",
          }}
        >
          {tooltipContent}
        </div>
      )}
      </Box>
    </Box>
  );
};

export default AdminDashboard;
