import React from "react";
import { Navigate } from "react-router-dom";

import PropTypes from 'prop-types';
const PrivateRoute = ({ component: Component, ...rest }) => {

  const userDetailString = localStorage.getItem("UserDetail");
  const userDetail = userDetailString ? JSON.parse(userDetailString) : {};

  const is_premium_user = userDetail.is_premium_user;

  const key = userDetail.key;

  const is_staff = userDetail.is_staff
  
  const isAuthenticated = !!key;
  
  
  
const renderComponent = () => {
    if (is_staff === true) {
      return <Navigate to="/admin" />
    }
    if(userDetail.role === 'employee') {
      return <Navigate to="/employee" />
    }
    if (isAuthenticated && is_premium_user === true) {
      return <Component {...rest} />;
    } else if (isAuthenticated && is_premium_user !== true) {
      return <Navigate to="/subscription" />;
    } else {
      return <Navigate to="/Login" />;
    }
  };

  return renderComponent();
};
export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

