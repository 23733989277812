import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { useLocation, useNavigate, Link } from "react-router-dom";
import SideBar from "../../components/sidebar";
import { useFormik } from "formik";
import DropdownItems from "../../utils/dropdownutils";
import { toast } from "react-toastify";
import { dispatchAll, canEnableField } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery,Button,TextField,Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import {
  clearMyPartnerActionData,
  getMyPartnerAction,
  myPartnerAction
} from "../../store/actions";
import { editAndViewRatingSchema } from "./editAndViewRatingSchema";


const RateMyCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [partnerData, setPartnerData] = React.useState({});
 

  const { state } = useLocation();
  const { partnerFullData, isEdit = false } = state || {};

  const {
    successData: ratingData,
    error: ratingError,
    errorInfo: ratingErrorInfo,
  } = useSelector((state) => state.myPartnerData) || {};

  const {
    loading : MyPartnerDataLoading,
    successData: MyPartnerData,
  } = useSelector((state) => state.getMyPartnerData) || {};

  useEffect(() => {
    if (ratingData) {
      navigate("/dashboard");
      toast.success("Rating updated successfully");
    }
  }, [ratingData]);

  useEffect(() => {
    if (ratingError) {
      toast.error(ratingErrorInfo);
    }
  }, [ratingError]);
  useEffect(() => {
    return () => dispatchAll([clearMyPartnerActionData], dispatch);
  }, []);

  const formatExistingDate = (dateString) => {
    if (!dateString) return "";
    console.log('Incoming date:', dateString);

    // Handle different possible date formats
    let year, month;

    if (dateString.includes('-')) {
      const parts = dateString.split('-');
      // Assuming format could be either DD-MM-YYYY or YYYY-MM-DD
      if (parts[0].length === 4) {
        [year, month] = parts;
      } else {
        [, month, year] = parts;
      }
    }

    // Ensure month is 2 digits and return YYYY-MM
    return `${year}-${month.padStart(2, '0')}`;
  };

  useEffect(() => {
    if (partnerFullData) {
      dispatch(getMyPartnerAction({ endPoint: `myPartners/${partnerFullData.partners_assoc_id}` }));
    }
  }, [partnerFullData]);
  
  useEffect(() => {
    if (MyPartnerDataLoading) {
      console.log("Loading...");
    } else if (MyPartnerData) {
      setPartnerData(MyPartnerData);
    }
  }, [MyPartnerDataLoading, MyPartnerData]);
  



  const isMobile = useMediaQuery("(max-width: 767px)")
  const initialValues = {
    score: isEdit
      ? DropdownItems.score.filter((v) => v.value === partnerData.my_rating)[0]
      : {},
    totaloutstanding:
      isEdit && partnerData.total_outstanding !== ""
        ? DropdownItems.totaloutstanding.filter(
          (v) => v.value === partnerData.total_outstanding
        )[0]
        : {},
    pendingrecievables:
      isEdit && partnerData.pending_receivables !== ""
        ? DropdownItems.pendingrecievable.filter(
          (v) => v.value === partnerData.pending_receivables
        )[0]
        : {},
   
    ontimepayment:
      isEdit && partnerData.on_time_Payment !== null && partnerData.on_time_Payment !== undefined
        ? DropdownItems.percentages.filter(
          (v) => v.value === partnerData.on_time_Payment
        )[0]
        : {},
    reachability:
      isEdit && partnerData.reachability !== null && partnerData.reachability !== undefined
        ? DropdownItems.reachability.filter(
          (v) => v.value === partnerData.reachability
        )[0]
        : {},
    recoveryprobability:
      isEdit && partnerData.recovery_probability !== null && partnerData.recovery_probability !== undefined
        ? DropdownItems.recoveryprobability.filter(
          (v) => v.value === partnerData.recovery_probability
        )[0]
        : {},
   
    since: isEdit ? formatExistingDate(partnerData.known_since) : "",
    comments: isEdit ? partnerData.comments : "",
    dso:
      isEdit && partnerData.dso !== ""
        ? DropdownItems.dso.filter((v) => v.value === partnerData.dso)[0]
        : {},
    
    legal:
      isEdit && partnerData.legal_proceedings !== null && partnerData.legal_proceedings !== undefined
        ? DropdownItems.legal.filter(
          (v) => v.value === Number(partnerData.legal_proceedings)
        )[0]
        : {},

  };



  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: editAndViewRatingSchema,
    onSubmit: (values) => {
  
      const payload = {
        score: values.score?.value,
        business_partner_assoc: partnerData.business_partner_id,
      };
      const formatDate = (dateString) => {
        if (!dateString) return "";
        const [year, month] = dateString.split("-");
        return `${year}-${month}-01`;
      };

      const fields = [
        { name: 'totaloutstanding', value: values.totaloutstanding?.value },
        { name: 'reachability', value: values.reachability?.value },
        { name: 'recoveryprobability', value: values.recoveryprobability?.value },
        { name: 'comments', value: values.comments },
        { name: 'dso', value: values.dso?.value },
        { name: 'legal', value: values.legal?.value },
        { name: 'capital', value: values.capital?.value },
        { name: 'assertworth', value: values.assets?.value },
        { name: 'gst', value: values.gst?.value },
        { name: 'since', value: formatDate(values.since) },
        { name: 'pendingrecievable', value: values.pendingrecievables?.value },
        { name: 'business_partner_assoc', value: partnerData.business_partner_id },
        { name: 'transactionPaidOntime', value: values.transactionPaidOntime?.value },
        { name: 'percentages', value: values.ontimepayment?.value }
      ];
      fields.forEach(field => {
        if (canEnableField(values.score, field.name)) {
          if (field.name === 'recoveryprobability') {
            payload['recovery_probability'] = field.value;
          } else if (field.name === 'legal') {
            payload['legal_proceedings'] = field.value;
          } else if (field.name === 'assertworth') {
            payload['assets'] = field.value;
          } else if (field.name === 'transactionPaidOntime') {
            payload['transaction_paid_ontime'] = field.value;
          } else if (field.name === 'percentages') {
            payload['on_time_Payment'] = field.value;
          } else if (field.name === 'pendingrecievable') {
            payload['pending_receivables'] = field.value;
          } else if (field.name === 'totaloutstanding') {
            payload['total_outstanding'] = field.value;
          } else if (field.name === 'gst') {
            payload['is_gst_paid'] = field.value;
          } else if (field.name === 'since') {
            payload['known_since'] = field.value;
          }


          else {
            payload[field.name] = field.value;
          }
        }
      });
      dispatch(myPartnerAction(payload));
    },
  });

  return (
    <>

<Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <SideBar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml:{xs:0,sm:0}
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            mt: {sm:'5%',xs:'20%'},
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: {xs:0,sm:3},
              width: "100%",
              maxWidth: {xs:'90%',sm:"900px"},
              minHeight: "100vh",
            }}
          >
            <div style={{ textAlign: "right"}}>
                <button
                  onClick={() => navigate('/dashboard')}
                  className="back"
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    color: "#2b4257",
                    cursor: "pointer",
                    position: "absolute",
                    right: 12
                  }}
                >
                  <KeyboardBackspaceIcon /> Back
                </button>
              </div>
            <Grid container spacing={2} style={{justifyContent: "center", alignItems: "center", width: "100%"}}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  component="h5"
                  align="center"
                  fontWeight="bold"
                  mt={{sm:'0%',xs:'10%'}}
                >
                  Rate My Partner
                </Typography>
              </Grid>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Grid container spacing={2} justifyContent='center'>
                <Grid container spacing={2} display='flex' justifyContent='center'>
                  {/* Score Field */}
                  <Grid item xs={12} marginLeft={{xs:'-9%',sm:'0%'}}>
                    <Typography align="center" sx={{ mb: 1 ,color:'#000'}}>
                      Score
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center",minWidth: "300px" }}>
                      <Select
                        defaultValue={values.score}
                        value={values.score}
                        name="score"
                        id="score"
                        onChange={(selectedScore) =>
                          setFieldValue("score", selectedScore)
                        }
                        options={DropdownItems.score}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            minWidth: "300px",
                            borderColor:'black'
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            width: "300px", 
                            height: "auto",
                            maxHeight: "300px",
                            overflowY: "auto", 
                          }),
                          menuList: (baseStyles) => ({
                            ...baseStyles,
                            maxHeight: "300px",
                          }),
                        }}
                        
                      />
                    </Box>
                    {errors.score && touched.score && (
                      <Typography color="error" align="center">
                        {errors.score.value}
                      </Typography>
                    )}
                  </Grid>
                    {/* Current Outstanding */}
                    <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "totaloutstanding") ? "block" : "none" ,  justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                       ml: { xs: -2.5, sm: 0 },
                    }}>
                      <Typography color='#000'>Current Outstanding</Typography>
                      <Select
                        value={values.totaloutstanding}
                        name="totaloutstanding"
                        id="totaloutstanding"
                        onChange={(selectedOption) => setFieldValue("totaloutstanding", selectedOption)}
                        options={DropdownItems.totaloutstanding}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor: "black",
                          }),
                        }}
                      />
                      {errors.totaloutstanding && touched.totaloutstanding && (
                        <Typography color="error">{errors.totaloutstanding?.value || errors.totaloutstanding}</Typography>
                      )}
                    </Grid>

                    {/* Days Since Outstanding */}
                    <Grid item xs={12} sm={Object.keys(values).length === 1 ? 12 : 4}sx={{ display: canEnableField(values.score, "dso") ? "block" : "none" ,justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                      ml: { xs: -2.5, sm: 0 },
                    }}>
                      <Typography color='#000'>Days Since Outstanding</Typography>
                      <Select
                        value={values.dso}
                        name="dso"
                        id="dso"
                        onChange={(selectedOption) => setFieldValue("dso", selectedOption)}
                        options={DropdownItems.dso}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor: "black",
                          }),
                        }}
                      />
                      {errors.dso && touched.dso && (
                        <Typography color="error">{errors.dso?.value || errors.dso}</Typography>
                      )}
                    </Grid>

                    {/* Pending Receivable */}
                    <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "pendingrecievable") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                      ml: { xs: -2.5, sm: 0 },
                     }}>
                      <Typography color='#000'>Pending Receivable</Typography>
                      <Select
                        value={values.pendingrecievables}
                        name="pendingrecievable"
                        id="pendingrecievable"
                        onChange={(selectedOption) => setFieldValue("pendingrecievables", selectedOption)}
                        options={DropdownItems.pendingrecievable}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor:'black'
                          }),
                        }}
                      />
                      {errors.pendingrecievables && touched.pendingrecievables && (
                        <Typography color="error">{errors.pendingrecievables?.value || errors.pendingrecievables}</Typography>
                      )}
                    </Grid>

                    {/* Pending Since */}
                    <Grid item xs={12}   sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "since") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                       ml: { xs: -2.5, sm: 0 },
                     }}>
                      <Typography color='#000'>Pending Since</Typography>
                      <TextField
                        fullWidth
                        type="month"
                        name="since"
                        id="since"
                        value={values.since}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          inputProps: {
                            max: new Date().toISOString().split("-").slice(0, 2).join("-"),
                          }, 
                            style: {  height: '38px'},
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                          borderColor:'black',
                          })
                        }}
                        error={Boolean(errors.since && touched.since)}
                        helperText={errors.since && touched.since ? errors.since : ""}
                      />
                    </Grid>

                    {/* Legal Proceedings */}
                    <Grid item xs={12}   sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "legal") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start", 
                     ml: { xs: -2.5, sm: 0 }, 
                     }}>
                      <Typography color='#000'>Legal Proceedings</Typography>
                      <Select
                        value={values.legal}
                        name="legal"
                        id="legal"
                        onChange={(selectedOption) => setFieldValue("legal", selectedOption)}
                        options={DropdownItems.legal}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor: "black",
                          }),
                        }}
                      />
                      {errors.legal && touched.legal && (
                        <Typography color="error">{errors.legal?.value || errors.legal}</Typography>
                      )}
                    </Grid>

                    {/* Reachability */}
                    <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "reachability") ? "block" : "none",justifyContent:'center',
                      ml: { xs: -2.5, sm: 0 },
                    }}>
                      <Typography color='#000'>Reachability</Typography>
                      <Select
                        value={values.reachability}
                        name="reachability"
                        id="reachability"
                        onChange={(selectedOption) => setFieldValue("reachability", selectedOption)}
                        options={DropdownItems.reachability}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor: "black",
                          }),
                        }}
                      />
                      {errors.reachability && touched.reachability && (
                        <Typography color="error">{errors.reachability?.value || errors.reachability}</Typography>
                      )}
                    </Grid>

                    {/* Recovery Probability */}
                    <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{ display: canEnableField(values.score, "recoveryprobability") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                        ml: { xs: -2.5, sm: 0 },
                     }}>
                      <Typography color='#000'>Recovery Probability</Typography>
                      <Select
                        value={values.recoveryprobability}
                        name="recoveryprobability"
                        id="recoveryprobability"
                        onChange={(selectedOption) => setFieldValue("recoveryprobability", selectedOption)}
                        options={DropdownItems.recoveryprobability}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor: "black",
                          }),
                        }}
                      />
                      {errors.recoveryprobability && touched.recoveryprobability && (
                        <Typography color="error">{errors.recoveryprobability?.value || errors.recoveryprobability}</Typography>
                      )}
                    </Grid>

                    {/* On-time Payment */}
                    <Grid item xs={12}    sm={Object.keys(values).length === 1 ? 12 : 4} sx={{    display: canEnableField(values.score, "percentages") ? "block" : "none",justifyContent: Object.keys(values).length === 1 ? "center" : "flex-start",
                       ml: { xs: -2.5, sm: 0 },
                     }}>
                      <Typography color='#000'>On-time Payment</Typography>
                      <Select
                        
                        value={values.ontimepayment}
                        name="ontimepayment"
                        id="ontimepayment"
                        onChange={(selectedOption) => setFieldValue("ontimepayment", selectedOption)}
                        options={DropdownItems.percentages}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "100%",
                            maxWidth: "300px",
                            borderColor: "black",
                          }),
                        }}
                      />
                      {errors.ontimepayment && touched.ontimepayment && (
                        <Typography color="error">{errors.ontimepayment?.value || errors.ontimepayment}</Typography>
                      )}
                    </Grid>
                    </Grid>
                
                {/* Comments */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft:{xs:'2%',sm:'15%'},
                        alignItems: values.score && values.score.value >= 9 ? 'center' : 'start',
                        justifyContent: values.score && values.score.value >= 9 ? 'center' : 'flex-start',
                        minWidth: {xs:290,sm:600},
                        margin: 'auto',
                      }}
                    >
                      <Typography
                        component="label"
                        htmlFor="comments"
                        sx={{ marginTop: 2, fontSize: '1rem', fontWeight: 'bold' }}
                      >
                        Comments
                      </Typography>
                      <TextField
                        type="text"
                        name="comments"
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="comments"
                        placeholder="Comments"
                        multiline
                        minRows={1}
                        maxRows={6}
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                              height: 'auto'    
                          }
                        }}
                        error={Boolean(errors.comments && touched.comments)}
                        inputProps={{ maxLength: 300 }}
                        helperText={
                          errors.comments && touched.comments ? (
                            <Typography sx={{ color: 'red', fontSize: '0.875rem' }}>{errors.comments}</Typography>
                          ) : null
                        }
                      />
                    </Box>
                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          background:
                            "linear-gradient(90deg, #65A3DD 0%, #1584D4 48.6%, #5D8FBD 100%)",
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
    </>
  );
};
export default RateMyCustomer;
